<template>
  <div class="home">
    <h1>Scholar</h1>
    <h3>Submission Stats</h3>
    <div class="grid-x grid-margin-x panel panel--row " >
      <div class="panel panel--dark cell medium-3">
        <h4 class="centre">Total Submissions</h4>
        <p class="centre"><strong class="info badge badge--lozenge badge--large">{{ dashboard.stats.submissionsTotal }}</strong></p>
      </div>
      <div class="panel panel--dark cell medium-3">
        <h4 class="centre">Total Submission Users</h4>
        <p class="centre"><strong class="info badge badge--lozenge badge--large">{{ dashboard.stats.submissionUsersTotal }}</strong></p>
      </div>
    </div>
  </div>
</template>

<script>

  import { mapState } from 'vuex'

  export default {
      data() {
        return {
          isFilterActive: false,
          filter: {
            q: '',
            perPage: 10,
            page: parseInt(this.$route.query.page) || 1
          }
        }
      },
      created() {
        this.$store.dispatch('dashboard/fetchDashboard', this.filter)
      },
      computed: {
        perPage(){
          return this.filter.perPage;
        },
        page() {
          return this.filter.page;
        },
        isLoading() {
          return this.tax.isLoading
        },
        ...mapState(['dashboard'])
      },
  }
</script>
<style lang="scss">
  .centre {
    text-align: center;
  }
</style>
