import UserService from '@/services/UserService.js'

export const namespaced = true

export const state = {
        users: [],
        usersTotal: 0,
        user: {
            roles: {
                assigned: [],
                available: []
            }
        },
        favourites: [],
        favourite: [],
        isLoading: true,
        meta: {},
    }
export const mutations = {
    ADD_USER(state, user) {
        state.users.push(user)
    },
    UPDATE_USER(state, user) {
        state.user = user
    },
    DELETE_USER(state, index) {
        state.users.splice(index)
    },
    SET_USERS(state, users) {
        state.users = users
    },
    SET_META(state, meta) {
        state.meta = meta
    },
    SET_USERS_TOTAL(state, total) {
        state.usersTotal = total
    },
    SET_USER(state, user) {
        state.user = user
    },
    SET_FAVOURITES(state, favourites) {
        state.favourites = favourites
    },
    SET_FAVOURITE(state, favourite) {
        state.favourite = favourite
    },
    SET_IS_LOADING(state, isLoading) {
        state.isLoading = isLoading
    }
  }

export const actions = {
    createUser({ commit, dispatch}, user) {

        return UserService.postUser(user).then(response => {
            commit('ADD_USER', user)
            const notification = {
                type: 'success',
                message: 'New user has been created!'
            }
            dispatch('notification/add', notification, {root: true})

            return response.data.data
        }).catch(error => {
            const notification = {
                type: 'error',
                message: error.response.data.message
            }
            dispatch('notification/add', notification, {root: true})

            const errors = error.response.data.errors
            Object.keys(errors).forEach(key => {
                dispatch('error/add', {key, message: errors[key]}, {root: true})
            });

            throw error
        })
    },
    updateUser({ commit, dispatch}, user) {

        return UserService.updateUser(user).then(response => {
            commit('UPDATE_USER', response.data.data)

            const notification = {
                type: 'success',
                message: 'User has been updated!'
            }
            dispatch('notification/add', notification, {root: true})


        }).catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem updating the user: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})

            const errors = error.response.data.errors
            Object.keys(errors).forEach(key => {
                dispatch('error/add', {key, message: errors[key]}, {root: true})
            });

            throw error
        })
    },
    newsletterForce({ commit, dispatch}, user) {

        return UserService.newsletterForce(user).then(response => {
            commit('UPDATE_USER', response.data.data)

            const notification = {
                type: 'success',
                message: 'Newsletter sent'
            }
            dispatch('notification/add', notification, {root: true})


        }).catch(error => {
            const notification = {
                type: 'error',
                message: error.response.data.error || 'There was a problem updating the user: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})


            throw error
        })
    },
    deleteUser({ commit, dispatch}, id) {

      const user = getters.getUserById(id)
      const userIndex = state.users.indexOf(user);

        return UserService.deleteUser(id).then(() => {
            commit('DELETE_USER', userIndex)

            const notification = {
                type: 'success',
                message: 'User has been delete!'
            }
            dispatch('notification/add', notification, {root: true})
        }).catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem deleting the user: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})
            throw error
        })
    },
    exportUsers({commit, dispatch}, filters) {

        return UserService.exportUsers(filters)
            .then(response => {
                commit('SET_IS_LOADING', false)

                var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                var fileLink = document.createElement('a');

                fileLink.href = fileURL;
                fileLink.setAttribute('download', 'TBUserExport.csv');
                document.body.appendChild(fileLink);

                fileLink.click();

        })
        .catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem exporting users: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})
            throw error
        })
    },
    fetchUsers({commit, dispatch}, filters) {
      UserService.getUsers(filters)
      .then(response => {
        commit('SET_USERS', response.data.data)
        commit('SET_META', response.data.meta)
        commit('SET_USERS_TOTAL', response.data.meta.total)
        commit('SET_IS_LOADING', false)
      })
      .catch(error => {
        const notification = {
            type: 'error',
            message: 'There was a problem getting users: ' + error.message
        }
        dispatch('notification/add', notification, {root: true})
      })
    },
    fetchUser({commit, dispatch}, id) {

        UserService.getUser(id)
        .then(response => {
          commit('SET_USER', response.data.data)
          commit('SET_IS_LOADING', false)
        })
        .catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem getting a user: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})
        })
    },
    fetchFavourites({commit, dispatch}) {
        UserService.getFavourites()
            .then(response => {
                commit('SET_FAVOURITES', response.data)
                commit('SET_IS_LOADING', false)
            })
            .catch(error => {
                const notification = {
                    type: 'error',
                    message: 'There was a problem getting users saved content: ' + error.message
                }
                dispatch('notification/add', notification, {root: true})
            })
    },
    fetchFavourite({commit, dispatch}, {id}) {
        UserService.getFavourite(id)
            .then(response => {
                commit('SET_FAVOURITE', response.data)
                commit('SET_IS_LOADING', false)
            })
            .catch(error => {
                const notification = {
                    type: 'error',
                    message: 'There was a problem getting the users saved content: ' + error.message
                }
                dispatch('notification/add', notification, {root: true})
            })
    },
  }

export const getters = {
    getUserById: state => id => {
      return state.users.find(user => user.id == id)
    }
  }
