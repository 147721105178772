<template>
  <div class="users">
    <h1>Users : {{ user.first_name }} {{ user.last_name }}</h1>
    <div>
    <p v-if="isLoading">Loading...</p>
    <template v-else >

      <div class="grid-x grid-margin-x" >
        <div class="panel cell medium-8">
          <h3>User Details</h3>
          <button class="button" @click="edit">Edit</button>
          <template v-if="!isEditing">
            <p>{{ user.first_name }} {{ user.last_name }}</p>
            <p>{{ user.email }}</p>
            <p>Telephone: {{user.telephone}}</p>
            <p>Country: {{user.country }}</p>
            <p>Owner? {{ user.owner ? 'Yes' : 'No' }}</p>
            <p>Registered: <strong class="info badge badge--lozenge ">{{ user.created_at }}</strong></p>
          </template>
          <template v-else>
            <form @submit.prevent="update">

              <BaseInput
                v-model="user.first_name"
                label="First Name"
                type="text"
                name="first_name"
               />

              <BaseInput
                v-model="user.last_name"
                label="Last Name"
                type="text"
                name="last_name"
               />

              <BaseInput
                v-model="user.email"
                label="Email"
                type="email"
                name="email"
               />

              <BaseInput
                v-model="user.telephone"
                label="Telephone"
                type="text"
                name="telephone"
               />

              <BaseSelect
                :options="formOptions.countries"
                v-model="user.country"
                label="Select a country"
                name="country"
               />

              <BaseButton type="submit" text="Update" />
          </form>
          </template>

          <template v-if="isVerified">
          <p>Verified: <strong class="success badge badge--lozenge ">{{ user.verified.time }}</strong> </p>
          </template>
          <template v-else>
            <p>Verified: <strong class="error badge badge--lozenge ">NOT VERIFIED</strong> <BaseButton @click="verifyUser" text="Verify User" class="button--small button--outline" /></p>
          </template>

          <template v-if="isApproved">
          <p>Approved: <strong class="success badge badge--lozenge ">{{ user.approved.time }}</strong> </p>
          </template>
          <template v-else>
            <p>Approved: <strong class="error badge badge--lozenge ">NOT APPROVED</strong> <BaseButton @click="approveUser" text="Approve User" class="button--small button--outline" /></p>
          </template>

          <template v-if="!isEditingPassword">
            <BaseButton @click="editPassword" text="Reset Password" />
          </template>
          <template v-else>
            <form @submit.prevent="update">

              <BaseInput
                v-model="user.password"
                label="Password"
                type="password"
                name="password"
               />
              <BaseInput
                v-model="user.password_confirmation"
                label="Password Confirmation"
                type="password"
                name="password_confirmation"
               />


              <BaseCheckbox
                v-model="user.email_password"
                label="Email new password to user?"
                name="email_password"
                id="email_password"
              />

              <BaseButton type="submit" text="Reset Password" />
          </form>
          </template>

        </div>
        <div class="panel cell medium-4">
          <h3>Roles</h3>
          <template v-if="rolesAssigned.length">
            <span v-for="(item) in rolesAssigned" :key="item.id" :item="item" class="tag" >
              {{ item.display_name }}
              <BaseButton class="button button--remove" text="Remove" @click="removeRole(item.name)" />
            </span>
          </template>
          <p v-else>No roles assigned</p>

          <template v-if="rolesAvailable.length">
            <form class="form-min" @submit.prevent="update">
              <BaseSelect
                v-model="user.addRole"
                :options="rolesAvailable"
                optionValue="name"
                optionName="display_name"
                label="Assign new role"
               />
              <BaseButton text="Set" />
            </form>
          </template>
          <p v-else>No more roles available to assign</p>
          <br/>


        </div>
      </div>

      <div class="grid-x grid-margin-x" >
        <div class="panel cell medium-12">
          <p>Click below to delete this user, please note all corresponding data will also be removed.</p>
          <BaseButton @click="remove" text="Delete" class="button button--danger" />
        </div>
      </div>
    </template>
    </div>
  </div>
</template>

<script>
    import { mapState } from 'vuex'

    export default {
        props: ["id"],
        data() {
          return {
            isEditing: false,
            isEditingPassword: false,
            emailShow: false,
          }
        },
        components : {
        },
        created() {
          this.$store.dispatch('error/clear')
          this.$store.dispatch('authentication/fetchFormOptions')
          this.$store.dispatch('user/fetchUser', this.id)

        },
        computed: {
          isLoading() {
            return this.user.isLoading
          },
          rolesAssigned() {
            return (this.user.roles) ? this.user.roles.assigned : []
          },
          rolesAvailable() {
            return (this.user.roles) ? this.user.roles.available : []
          },
          isApproved() {
            return (this.user.approved) ? this.user.approved.status : false
          },
          isVerified() {
            return (this.user.verified) ? this.user.verified.status : false
          },
          loginLogs() {
            return (this.user.eventLogin) ? this.user.eventLogin : false
          },
          ...mapState({
            user: state => state.user.user,
            formOptions: state => state.authentication.formOptions,
            formSources: state => (state.authentication.formOptions.data.length) ? state.authentication.formOptions.data[0].options : [{ name: '', value: ''}]
          })
        },
        methods: {
          remove() {
            this.$store.dispatch('user/deleteUser', this.id)
          },
          update() {
            this.$store.dispatch('error/clear')
            this.$store.dispatch('user/updateUser', this.user)
            this.isEditing = false
            this.isEditingPassword = false
          },
          edit() {
            this.isEditing = (this.isEditing) ? false : true

          },
          editPassword() {
            this.isEditingPassword = (this.isEditingPassword) ? false : true
          },
          verifyUser() {
            this.user.verifyEmail = true
            this.update()
          },
          approveUser() {
            this.user.approveUser = true
            this.update()
          },
        }

    }
</script>
<style type="scss">
  .uppercase {
    text-transform: uppercase;
  }
  .button-show-email {
    float:right;
  }
</style>
