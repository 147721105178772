<template>
  <div class="contracts">
    <h1>Contracts - Create a new contract</h1>
    <div>
      <form @submit.prevent="createContract">

        <BaseInput
          v-model="contract.name"
          label="Name"
          type="text"
          name="name"
         />

        <BaseButton type="submit" text="Add New Contract" />

      </form>
    </div>
  </div>
</template>

<script>
    // import Datepicker from 'vue3-datepicker'

    export default {
        data() {
          return {
            contract: this.createFreshContractObject(),
            error: {},

          }
        },
        methods: {
          createContract() {
            this.$store.dispatch('error/clear')
            this.$store.dispatch('contract/createContract', this.contract).then(response => {
              this.$router.push({
                name: 'contracts-detail',
                params: { id: response.id }
              })
              this.contract = this.createFreshContractObject()
            }).catch(err => {
                this.error = err
            })
          },
          createFreshContractObject() {
            const contract = this.$store.state.contract
            const id = Math.floor(Math.random*10000)

            return {
              id: id,
              contract: contract
            }
          }
        }

    }
</script>
