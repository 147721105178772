import SubmissionService from '@/services/SubmissionService.js'

export const namespaced = true

export const state = {
        submissions: [],
        submissionsTotal: 0,
        submission: {},
        isLoading: true,
        meta: {},
    }
export const mutations = {
    ADD(state, submission) {
        state.submissions.push(submission)
    },
    UPDATE(state, submission) {
        state.submission = submission
    },
    DELETE(state, index) {
        state.submissions.splice(index)
    },
    SET_LIST(state, submissions) {
        state.submissions = submissions
    },
    SET_META(state, meta) {
        state.meta = meta
    },
    SET_LIST_TOTAL(state, total) {
        state.submissionsTotal = total
    },
    SET_ITEM(state, submission) {
        state.submission = submission
    },
    SET_IS_LOADING(state, isLoading) {
        state.isLoading = isLoading
    }
  }

export const actions = {
    createSubmission({ commit, dispatch}, submission) {

        return SubmissionService.postSubmission(submission).then(response => {
            commit('ADD', submission)
            const notification = {
                type: 'success',
                message: 'New content has been created!'
            }
            dispatch('notification/add', notification, {root: true})

            return response.data.data
        }).catch(error => {
            const notification = {
                type: 'error',
                message: error.response.data.message
            }
            dispatch('notification/add', notification, {root: true})

            const errors = error.response.data.errors
            Object.keys(errors).forEach(key => {
                dispatch('error/add', {key, message: errors[key]}, {root: true})
            });

            throw error
        })
    },
    updateSubmission({ commit, dispatch}, submission) {

        return SubmissionService.updateSubmission(submission).then(response => {
            commit('UPDATE', response.data.data)

            const notification = {
                type: 'success',
                message: 'Submission has been updated!'
            }
            dispatch('notification/add', notification, {root: true})


        }).catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem updating the submission: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})

            const errors = error.response.data.errors
            Object.keys(errors).forEach(key => {
                dispatch('error/add', {key, message: errors[key]}, {root: true})
            });

            throw error
        })
    },
    deleteSubmission({ commit, dispatch}, id) {

      const submission = getters.getSubmissionById(id)
      const submissionIndex = state.submissions.indexOf(submission);

        return SubmissionService.deleteSubmission(id).then(() => {
            commit('DELETE', submissionIndex)

            const notification = {
                type: 'success',
                message: 'Submission has been deleted!'
            }
            dispatch('notification/add', notification, {root: true})
        }).catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem deleting the submission: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})
            throw error
        })
    },
    exportSubmissions({commit, dispatch}, filters) {

        return SubmissionService.exportSubmissions(filters)
            .then(response => {
                commit('SET_IS_LOADING', false)

                var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                var fileLink = document.createElement('a');

                fileLink.href = fileURL;
                fileLink.setAttribute('download', 'ScholarSubmissionExport.csv');
                document.body.appendChild(fileLink);

                fileLink.click();

        })
        .catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem exporting submissions: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})
            throw error
        })
    },
    fetchSubmissions({commit, dispatch}, filters) {
      SubmissionService.getSubmissions(filters)
      .then(response => {
        commit('SET_LIST', response.data.data)
        commit('SET_META', response.data.meta)
        if(response.data.meta !== undefined) {
            commit('SET_LIST_TOTAL', response.data.meta.total)
        }
        commit('SET_IS_LOADING', false)
      })
      .catch(error => {
        const notification = {
            type: 'error',
            message: 'There was a problem getting the submissions: ' + error.message
        }
        dispatch('notification/add', notification, {root: true})
      })
    },
    fetchSubmission({commit, dispatch}, id) {

        SubmissionService.getSubmission(id)
        .then(response => {
          commit('SET_ITEM', response.data)
          commit('SET_IS_LOADING', false)
        })
        .catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem getting a submission: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})
        })

    }
  }

export const getters = {
    getSubmissionById: state => id => {
      return state.submissions.find(submission => submission.id == id)
    }
  }
