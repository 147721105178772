import apiClient from './apiClient.js'


export default {
    newDocumentSection() {
        return new apiClient().get('/document-sections/create');
    },
    getDocumentSections(filters) {
        let filtersEncoded = new URLSearchParams(filters).toString();
        return new apiClient().get('/document-sections?' + filtersEncoded);
    },
    getDocumentSection(id) {
        return new apiClient().get('/document-sections/'+id);
    },
    postDocumentSection(doc) {
        return new apiClient().post('/document-sections', doc);
    },
    updateDocumentSection(doc) {
        return new apiClient().patch('/document-sections/'+doc.id, doc);
    },
    deleteDocumentSection(id) {
        return new apiClient().delete('/document-sections/'+id);
    },
    authCheck(authToken) {

        if (authToken === null) {
            console.log('not logged in');
        }

    }
}
