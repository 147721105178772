import apiClient from './apiClient.js'


export default {
    getContracts(filters) {
        let filtersEncoded = new URLSearchParams(filters).toString();
        return new apiClient().get('/contract?' + filtersEncoded);
    },
    getContract(id) {
        return new apiClient().get('/contract/'+id);
    },
    postContract(role) {
        return new apiClient().post('/contract', role);
    },
    updateContract(role) {
        return new apiClient().patch('/contract/'+role.id, role);
    },
    deleteContract(id) {
        return new apiClient().delete('/contract/'+id);
    },
    authCheck(authToken) {

        if (authToken === null) {
            console.log('not logged in');
        }

    }
}
