<template>
  <div class="record">
    <h1>Submission : {{ submission.created_at }}</h1>
    <div>
    <p v-if="isLoading">Loading...</p>
    <template v-else >

      <div class="grid-x grid-margin-x" >
        <div class="panel cell medium-8">
          <h3>Submission Details</h3>

            <p><strong>{{ submission.id }}</strong></p>
            <p class="margin">Created: <strong class="info badge badge--lozenge">{{ submission.created_at }}</strong></p>



        </div>
        <div class="panel cell medium-4">
          <p>Click below to delete this submission.</p>
          <BaseButton text="Delete" @click="remove" class="button button--danger" />

        </div>

      </div>

    </template>
    </div>
  </div>
</template>

<script>
    import { mapState } from 'vuex'

    export default {
        props: ["id"],
        data() {
          return {
            isEditing: false
          }
        },
        created() {
          this.$store.dispatch('error/clear')
          this.$store.dispatch('submission/fetchSubmission', this.id)

        },
        computed: {
          isLoading() {
            return this.submission.isLoading
          },
          isDisabled() {
            return false
          },
          ...mapState({
            submission: state => state.submission.submission
          }),
        },
        methods: {
          remove() {
            this.$store.dispatch('submission/deleteSubmission', this.id)
          },
          update() {
            this.$store.dispatch('error/clear')
            this.$store.dispatch('submission/updateSubmission', this.submission)
            this.isEditing = false
          },
          edit() {
            this.isEditing = (this.isEditing) ? false : true

          }
        }

    }
</script>
