<template>
  <p v-if="isLoading">Loading...</p>
  <div v-else class="users">
    <h1>Setting : {{ setting.title }}</h1>
    <div>
      <div class="grid-x grid-margin-x" >
        <div class="panel cell medium-12">
          <h3>Setting Details</h3>
          <button class="button" @click="edit">Edit</button>
          <template v-if="!isEditing">
            <p><strong>{{ setting.title }}</strong></p>
          </template>
          <template v-else>
            <form @submit.prevent="update">

              <BaseInput
                v-model="setting.title"
                label="Title"
                type="text"
                name="title"
               />

              <BaseButton type="submit" text="Update" />
          </form>
          </template>

        </div>
        <div class="panel cell medium-12" v-if="setting.type == 'form'">
          <FormBuilder />

        </div>

      </div>
  </div>
</div>
</template>

<script>
    import { mapState } from 'vuex'
    import FormBuilder from '@/components/FormBuilder'

    export default {
        props: ["id"],
        data() {
          return {
            isEditing: false,
            data: [{
                    'name': 'source',
                    'type': 'select',
                    'options': [{}]
                  },{
                    'name': ''
                  }]
          }
        },
        components : {
          FormBuilder
        },
        created() {
          this.$store.dispatch('error/clear')
          this.$store.dispatch('setting/fetchSetting', this.id)

        },
        computed: {
          isDisabled() {
            return false
          },
          ...mapState({
            setting: state => state.setting.setting,
            isLoading: state => state.setting.isLoading,
          }),
        },
        methods: {
          update() {
            this.$store.dispatch('error/clear')
            this.$store.dispatch('setting/updateSetting', this.setting)
            this.isEditing = false
          },
          edit() {
            this.isEditing = (this.isEditing) ? false : true

          },
        }

    }
</script>
