<template>
  <div class="documents">
    <h1>Documents - Create a new document</h1>
    <div>
      <form @submit.prevent="createDocument">

        <BaseInput
          v-model="document.name"
          label="Name"
          type="text"
          name="name"
         />

        <BaseSelect
          :options="template.contracts"
          v-model="document.contract_id"
          label="Select contract"
          optionValue="id"
          optionName="name"
         />


        <BaseButton type="submit" text="Add New Document" />

      </form>
    </div>
  </div>
</template>

<script>
    import { mapState } from 'vuex'

    export default {
        data() {
          return {
            document: this.createFreshDocumentObject(),
            error: {},

          }
        },
        created() {
          this.$store.dispatch('error/clear')
          this.$store.dispatch('document/newDocument')
        },
        computed: {
          ...mapState({
            template: state => state.document.template
          })
        },
        methods: {
          createDocument() {
            this.$store.dispatch('error/clear')
            this.$store.dispatch('document/createDocument', this.document).then(response => {
              this.$router.push({
                name: 'documents-detail',
                params: { id: response.id }
              })
              this.document = this.createFreshDocumentObject()
            }).catch(err => {
                this.error = err
            })
          },
          createFreshDocumentObject() {
            const document = this.$store.state.document
            const id = Math.floor(Math.random*10000)

            return {
              id: id,
              contract_id: 0,
              document: document
            }
          }
        }

    }
</script>
