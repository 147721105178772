import SettingService from '@/services/SettingService.js'

export const namespaced = true

export const state = {
        settings: [],
        settingsTotal: 0,
        setting: {},
        isLoading: true,
        meta: {},
    }
export const mutations = {
    UPDATE(state, setting) {
        state.setting = setting
    },
    SET_LIST(state, settings) {
        state.settings = settings
    },
    SET_META(state, meta) {
        state.meta = meta
    },
    SET_LIST_TOTAL(state, total) {
        state.settingsTotal = total
    },
    SET_ITEM(state, setting) {
        state.setting = setting
    },
    SET_IS_LOADING(state, isLoading) {
        state.isLoading = isLoading
    }
  }

export const actions = {

    updateSetting({ commit, dispatch}, data) {

        return SettingService.updateSetting(data).then(response => {
            commit('UPDATE', response.data)

            const notification = {
                type: 'success',
                message: 'Setting rate has been updated!'
            }
            dispatch('notification/add', notification, {root: true})


        }).catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem updating the setting rate: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})

            const errors = error.response.data.errors
            Object.keys(errors).forEach(key => {
                dispatch('error/add', {key, message: errors[key]}, {root: true})
            });

            throw error
        })
    },
    fetchSettings({commit, dispatch}, filters) {
      SettingService.getSettings(filters)
      .then(response => {
        commit('SET_LIST', response.data.data)
        commit('SET_META', response.data.meta)
        if(response.data.meta !== undefined) {
            commit('SET_LIST_TOTAL', response.data.meta.total)
        }
        commit('SET_IS_LOADING', false)
      })
      .catch(error => {
        const notification = {
            type: 'error',
            message: 'There was a problem getting the settings: ' + error.message
        }
        dispatch('notification/add', notification, {root: true})
      })
    },
    fetchSetting({commit, dispatch}, id) {

        SettingService.getSetting(id)
        .then(response => {
          commit('SET_ITEM', response.data)
          commit('SET_IS_LOADING', false)
        })
        .catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem getting a setting: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})
        })

    }
  }

export const getters = {
    getSettingById: state => id => {
      return state.settings.find(setting => setting.id == id)
    }
  }
