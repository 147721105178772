<template>
  <div class="users">
    <h1>Contracts : {{ contract.name }}</h1>
    <div>
    <p v-if="isLoading">Loading...</p>
    <template v-else >

      <div class="grid-x grid-margin-x" >
        <div class="panel cell medium-8">
          <h3>Contract Details</h3>
          <button class="button" @click="edit">Edit</button>
          <template v-if="!isEditing">
            <p><strong>{{ contract.name }}</strong></p>
            <p class="margin">Created: <strong class="info badge badge--lozenge">{{ contract.created_at }}</strong></p>
          </template>
          <template v-else>
            <form @submit.prevent="update">

              <BaseInput
                v-model="contract.name"
                label="Name"
                type="text"
                name="name"
               />

              <BaseButton type="submit" text="Update" />
          </form>
          </template>

        </div>
        <div class="panel cell medium-4">
          <p>Click below to delete this contract.</p>
          <template v-if="!contract.protected">
            <BaseButton text="Delete" @click="remove" class="button button--danger" />
          </template>
          <template v-else>
            <p>This contract can not be removed</p>
          </template>
        </div>

      </div>
      <div class="grid-x grid-margin-x" >
        <div class="panel cell medium-6">
          <h3>Users</h3>

          <template v-if="users.length">
            <ListLoop v-for="(item) in users" :key="item.id" :item="item" route="users-detail" itemName="email" >
              <template v-slot:details>
                <p><strong>{{ item.name }}</strong></p>
                <p>{{ item.email }}</p>
                <p class="margin">Created : <strong class="info badge badge--lozenge">{{ item.created_at }}</strong></p>
              </template>
            </ListLoop>
          </template>
          <p v-else class="warning badge badge--lozenge">No users in this contract yet!</p>
        </div>
      </div>
    </template>
    </div>
  </div>
</template>

<script>
    import ListLoop from '@/components/ListLoop.vue'
    import { mapState } from 'vuex'

    export default {
        props: ["id"],
        data() {
          return {
            isEditing: false
          }
        },
        components : {
          ListLoop
        },
        created() {
          this.$store.dispatch('error/clear')
          this.$store.dispatch('contract/fetchContract', this.id)

        },
        computed: {
          isLoading() {
            return this.contract.isLoading
          },
          isDisabled() {
            return false
          },
          ...mapState({
            contract: state => state.contract.contract
          }),
          users() {
            return (this.contract.users) ? this.contract.users : []
          },
        },
        methods: {
          remove() {
            this.$store.dispatch('contract/deleteContract', this.id)
          },
          update() {
            this.$store.dispatch('error/clear')
            this.$store.dispatch('contract/updateContract', this.contract)
            this.isEditing = false
          },
          edit() {
            this.isEditing = (this.isEditing) ? false : true

          }
        }

    }
</script>
