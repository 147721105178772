import apiClient from './apiClient.js'


export default {
    newDocument() {
        return new apiClient().get('/document/create');
    },
    getDocuments(filters) {
        let filtersEncoded = new URLSearchParams(filters).toString();
        return new apiClient().get('/document?' + filtersEncoded);
    },
    getDocument(id) {
        return new apiClient().get('/document/'+id);
    },
    postDocument(doc) {
        return new apiClient().post('/document', doc);
    },
    updateDocument(doc) {
        return new apiClient().patch('/document/'+doc.id, doc);
    },
    deleteDocument(id) {
        return new apiClient().delete('/document/'+id);
    },
    authCheck(authToken) {

        if (authToken === null) {
            console.log('not logged in');
        }

    }
}
