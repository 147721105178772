import { createStore } from 'vuex'
import * as authentication from '@/store/modules/authentication.js'
import * as contract from '@/store/modules/contract.js'
import * as contractType from '@/store/modules/contractType.js'
import * as dashboard from '@/store/modules/dashboard.js'
import * as document from '@/store/modules/document.js'
import * as documentCategory from '@/store/modules/documentCategory.js'
import * as documentSection from '@/store/modules/documentSection.js'
import * as documentSubSection from '@/store/modules/documentSubSection.js'
import * as error from '@/store/modules/error.js'
import * as eventLog from '@/store/modules/eventLog.js'
import * as form from '@/store/modules/form.js'
import * as layout from '@/store/modules/layout.js'
import * as media from '@/store/modules/media.js'
import * as notification from '@/store/modules/notification.js'
import * as role from '@/store/modules/role.js'
import * as setting from '@/store/modules/setting.js'
import * as submission from '@/store/modules/submission.js'
import * as submissionUser from '@/store/modules/submissionUser.js'
import * as user from '@/store/modules/user.js'

// Create a new store instance.
const store = createStore({
  modules: {
    authentication,
    contract,
    contractType,
    dashboard,
    document,
    documentCategory,
    documentSection,
    documentSubSection,
    error,
    eventLog,
    form,
    layout,
    media,
    notification,
    role,
    setting,
    submission,
    submissionUser,
    user,
  },
  state : {}
})

export default store
