<template>
  <div class="users">
    <h1>Users</h1>
    <template v-if="!isFilterActive">
      <BaseButton text="Filter" @click="filterToggle" />
    </template>
    <div v-else class="card">
      <div class="card-divider" style="justify-content: space-between;">
        <h3 class="no-margin">Filter</h3>
        <BaseButton text="X" class="button button--close" @click="filterToggle" />
      </div>
      <div class="card-section gid-container">
        <form @submit.prevent="filterResults" class="grid-x grid-margin-x">
          <div class="cell medium-4 large-3">
            <BaseInput
              v-model="filter.q"
              label="Search"
              type="search"
              name="q"
             />
          </div>
          <div class="cell medium-4 large-3">
            <BaseSelect
              :options="user.meta.filters.role || []"
              v-model="filter.role"
              label="Select a role"
              optionValue="id"
              optionName="name"
             />
          </div>
          <div class="cell medium-4 large-3">
            <BaseSelect
              :options="user.meta.filters.verified || []"
              v-model="filter.verified"
              label="Verified"
              optionValue="id"
              optionName="name"
             />
          </div>
          <div class="cell medium-4 large-3">
            <BaseDate
              v-model="filter.created_from"
              label="Registered from"
             />
          </div>
          <div class="cell medium-4 large-3">
            <BaseDate
              v-model="filter.created_until"
              label="Registered until"
             />
          </div>

          <div class="cell medium-4 large-3">
            <BaseButton type="submit" text="Filter" style="margin-top: 27px;" />
          </div>
        </form>
      </div>
    </div>

    <router-link :to="{ name: 'users-create'}">
      <BaseButton text="Add new user" />
    </router-link>

    <BaseButton text="Export CSV" class="button--outline" @click="exportCSV" :disabled="exporting" :class="{'active' : exporting}" />

    <p v-if="isLoading">Loading...</p>
    <template v-else >
      <div>
        <ListLoop v-for="(item) in user.users" :key="item.id" :item="item" route="users-detail" itemName="display_name" >
          <template v-slot:details>
            <p><strong>{{ item.display_name }}</strong></p>
            <p>{{ item.email }} - Tel: {{ item.telephone }}</p>
            <p class="margin">
              <strong v-if="!item.verified.status" class="error badge badge--lozenge">Not Verified</strong>
              <strong v-if="item.verified.status" class="success badge badge--lozenge">Verified - {{ item.verified.time }}</strong>
            </p>
            <p class="margin">
              <strong v-if="!item.approved.status" class="error badge badge--lozenge">Not Approved</strong>
              <strong v-if="item.approved.status" class="success badge badge--lozenge">Approved - {{ item.approved.time }} by: {{ item.approved.by.name }}</strong>
            </p>
            <p>Created: <strong>{{ item.created_at }}</strong></p>
          </template>
        </ListLoop>
      </div>

      <Pagination :page="page" :perPage="perPage" :filter="filter" routeName="users" :total="totalUsers" />
    </template>

  </div>
</template>

<script>
    import ListLoop from '@/components/ListLoop.vue'
    import Pagination from '@/components/Pagination.vue'
    import { mapState } from 'vuex'

    export default {
        data() {
          return {
            isFilterActive: false,
            exporting: false,
            filter: {
              q: this.$route.query.q || '',
              perPage: 10,
              page: parseInt(this.$route.query.page) || 1,
              role: this.$route.query.role || 'all',
              verified: this.$route.query.verified || 'all',
              created_from: this.$route.query.created_from || '',
              created_until: this.$route.query.created_until || '',
            }
          }
        },
        components: {
          ListLoop,
          Pagination
        },
        created() {
          this.$store.dispatch('user/fetchUsers', this.filter)
        },
        computed: {
          perPage(){
            return this.filter.perPage;
          },
          page() {
            return this.filter.page;
          },
          totalUsers() {
            return this.user.usersTotal
          },
          isLoading() {
            return this.user.isLoading
          },
          ...mapState(['user'])
        },
        methods : {
          filterToggle() {
            this.isFilterActive = (this.isFilterActive) ? false : true
          },
          filterResults() {
            this.$store.dispatch('user/fetchUsers', this.filter)
          },
          exportCSV() {
            this.exporting = true;
            this.$store.dispatch('user/exportUsers', this.filter).then(() => {
              this.exporting = false;
            })
          }
        }

    }
</script>
<style lang="scss" >
  // Loader Styles !Required!
  $spinner_speed: .675s;
  $spinner_radius: 80%;
  $spinner_fill_radius: 90px;

  $spinner_size: 20px;
  $spinner_thickness: 5px;
  $spinner_inner_circle: $spinner_size - $spinner_thickness;

  $spinner_color: #FFFFFF;
  $spinner_bg: #3adb76;
  $spinner_inner_bg: #696969;

.active {

span {
  text-indent: -2000px;
  overflow: hidden;
  border-radius: 80%;
  display: block;
  height: $spinner_size;
  width: $spinner_size;
  position: relative;
  animation: spin $spinner_speed linear 0s infinite normal;
  background: $spinner_bg;

  &:before,
  &:after {
    content: "";
    display: block;
    position: absolute;
  }

  &:before {
    border-radius: 0 $spinner_fill_radius $spinner_fill_radius 0;
    height: $spinner_size;
    width: 50%;
    top: 0; right: 0;
    z-index: 1;
    background: $spinner_color;
    background-image: linear-gradient( $spinner_bg, $spinner_color);
  }

  &:after {
    border-radius: $spinner_radius;
    height: $spinner_inner_circle;
    width: $spinner_inner_circle;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    background: $spinner_inner_bg;
  }


}
}
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style>
