<template>
  <div class="documentSubSection">
    <h1>Document Sub Section - Create a new sub-section</h1>
    <div>
      <form @submit.prevent="createDocumentSubSection">

        <BaseInput
          v-model="documentSubSection.name"
          label="Name"
          type="text"
          name="name"
         />

        <BaseButton type="submit" text="Add New sub section" />

      </form>
    </div>
  </div>
</template>

<script>
    // import Datepicker from 'vue3-datepicker'

    export default {
        data() {
          return {
            documentSubSection: this.createFreshDocumentObject(),
            error: {},

          }
        },
        methods: {
          createDocumentSubSection() {
            this.$store.dispatch('error/clear')
            this.$store.dispatch('documentSubSection/createDocumentSubSection', this.documentSubSection).then(response => {
              this.$router.push({
                name: 'documentSubSection-detail',
                params: { id: response.id }
              })
              this.documentSubSection = this.createFreshDocumentObject()
            }).catch(err => {
                this.error = err
            })
          },
          createFreshDocumentObject() {
            const documentSubSection = this.$store.state.documentSubSection
            const id = Math.floor(Math.random*10000)

            return {
              id: id,
              documentSubSection: documentSubSection
            }
          }
        }

    }
</script>
