<template>
  <div class="documentSubSections">
    <h1>Document SubSection : {{ documentSubSection.name }}</h1>
    <div>
    <p v-if="isLoading">Loading...</p>
    <template v-else >

      <div class="grid-x grid-margin-x" >
        <div class="panel cell medium-8">
          <h3>SubSection Details</h3>
          <button class="button" @click="edit">Edit</button>
          <template v-if="!isEditing">
            <p><strong>{{ documentSubSection.name }}</strong></p>
            <p class="margin">Created: <strong class="info badge badge--lozenge">{{ documentSubSection.created_at }}</strong></p>
          </template>
          <template v-else>
            <form @submit.prevent="update">

              <BaseInput
                v-model="documentSubSection.name"
                label="Name"
                type="text"
                name="name"
               />

              <BaseCheckbox
                  v-model="documentSubSection.preselect"
                  label="Always include documents listed below in this sub-section?"
                  name="preselect"
                  id="preselect"
              />

              <BaseSelect
                :options="template.types"
                v-model="documentSubSection.settings.type"
                label="Select type"
                optionValue="id"
                optionName="name"
             />

              <BaseButton type="submit" text="Update" />
          </form>
          </template>

        </div>
        <div class="panel cell medium-4">
          <p>Click below to delete this category.</p>
          <template v-if="!documentSubSection.protected">
            <BaseButton text="Delete" @click="remove" class="button button--danger" />
          </template>
          <template v-else>
            <p>This documentSubSection can not be removed</p>
          </template>
        </div>

      </div>
      <div class="grid-x grid-margin-x" >
        <div class="panel cell medium-12">
          <h3>Documents</h3>
          <div class="library-header">
            <BaseButton @click="uploading" :text="isUploadingButton"/>
            <MultiFileUpload v-if="isUploading"
                :contract_id="documentSubSection.contract_id"
                :document_id="documentSubSection.document_id"
                :document_section_id="documentSubSection.document_section_id"
                :document_sub_section_id="documentSubSection.id"
              />
          </div>

          <div v-if="files.length" class="grid-x grid-margin-x small-up-2 medium-up-4 large-up-6 xxlarge-up-8">
            <MediaListLoop
                v-for="(media) in files"
                :mediaObject="media"
                :key="media.id"
                @close-reveal="closedReveal"
            />
          </div>
          <p v-else class="warning badge badge--lozenge">No files in this sub section yet!</p>
        </div>
      </div>
    </template>
    </div>
  </div>
</template>

<script>
    import MediaListLoop from '@/components/MediaListLoop.vue'
    import MultiFileUpload from '@/components/MultiFileUpload.vue'
    import { mapState } from 'vuex'

    export default {
        props: ["id"],
        data() {
          return {
            isUploading: false,
            isUploadingButton: 'Upload files',
            isEditing: false
          }
        },
        components : {
          MediaListLoop,
          MultiFileUpload
        },
        created() {
          this.$store.dispatch('error/clear')
          this.$store.dispatch('documentSubSection/fetchDocumentSubSection', this.id)

        },
        computed: {
          isLoading() {
            return this.documentSubSection.isLoading
          },
          isDisabled() {
            return false
          },
          ...mapState({
            documentSubSection: state => state.documentSubSection.documentSubSection
          }),
          files() {
            return (this.documentSubSection.files) ? this.documentSubSection.files : []
          },
          template() {
            return this.documentSubSection.template ?? { types: []};
          }
        },
        methods: {
          remove() {
            this.$store.dispatch('documentSubSection/deleteDocumentSubSection', this.id)
          },
          update() {
            this.$store.dispatch('error/clear')
            this.$store.dispatch('documentSubSection/updateDocumentSubSection', this.documentSubSection)
            this.isEditing = false
          },
          edit() {
            this.isEditing = (this.isEditing) ? false : true

          },
          uploading() {
            this.isUploading = (this.isUploading) ? false : true
            this.isUploadingButton = (this.isUploading) ? 'Cancel' : 'Upload files'
          },
          uploadFiles() {

          },
          removeFile(fileID) {
            console.log
            this.$store.dispatch('media/deleteMedia', {
              fileID
            }).then(() => {
              this.$store.dispatch('error/clear')
              this.$store.dispatch('documentSubSection/fetchDocumentSubSection', this.id)
            }).catch(err => {
              this.error = err
            })
          },
        }

    }
</script>
