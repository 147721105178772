<template>
    <div id="nav" class="cell large-2">
      <nav>
          <ul class="no-bullet">
            <li :class="isActive(['dashboard'])" ><router-link :to="{ name: 'dashboard'}">Dashboard</router-link></li>
            <li :class="isActive(['users','roles','teams','invites','users-favourites'])" >
              <router-link :to="{ name: 'users'}">Users</router-link>
              <BaseButton v-show="users" @click="subToggle('users')" class="button button--minus" text="collapse" />
              <BaseButton v-show="!users" @click="subToggle('users')" class="button button--plus" text="expand" />
              <ul v-if="users">
                <li :class="isActive(['roles'])" ><router-link :to="{ name: 'roles'}">Roles</router-link></li>
              </ul>
            </li>
            <li :class="isActive(['contracts', 'media-library-index'])" >
              <router-link :to="{ name: 'contracts'}">Contracts</router-link>
              <BaseButton v-show="contracts" @click="subToggle('contracts')" class="button button--minus" text="collapse" />
              <BaseButton v-show="!contracts" @click="subToggle('contracts')" class="button button--plus" text="expand" />
              <ul v-if="contracts">
                <!--<li :class="isActive(['documents'])" ><router-link :to="{ name: 'documents'}">Documents</router-link></li>-->
              </ul>
            </li>
            <li :class="isActive(['documents', 'documentCategory'])" >
              <router-link :to="{ name: 'documents'}">Documents</router-link>
              <BaseButton v-show="documents" @click="subToggle('documents')" class="button button--minus" text="collapse" />
              <BaseButton v-show="!documents" @click="subToggle('documents')" class="button button--plus" text="expand" />
              <ul v-if="documents">
                <li :class="isActive(['document-categories'])" ><router-link :to="{ name: 'documentCategory'}">Categories</router-link></li>
              </ul>
            </li>
            <li :class="isActive(['submissions'])" >
              <router-link :to="{ name: 'submissions'}">Submissions</router-link>
            </li>

          </ul>
      </nav>
    </div>
</template>

<script>
    import { mapState } from 'vuex'

    export default {
        data() {
            return {
                contracts: false,
                documents: false,
                users: false,
                product: false,
                subscriptions: false,
                mailing: false,
            }
        },
        computed: {
          ...mapState({
            showSideNav: state => state.layout.showSideNav
          }),
        },
        methods: {
          subToggle(toggle) {
            this[toggle] = this[toggle] ? false : true
          },
          isActive(route) {
            let className = ''
            if (this.$route.matched[0] !== undefined && route.includes(this.$route.matched[0].name)) {
              className = 'is-active'
              if (this[this.$route.matched[0].name] !== undefined) {
                this[this.$route.matched[0].name] = true
              }
            }

            return className

          },
          toggleSideBar() {
            this.showSideNav = this.showSideNav ? false : true


            if (this.showSideNav) {
              this.$store.dispatch('layout/closeSideNav')
            } else {
              this.$store.dispatch('layout/showSideNav')
            }

          }
        }

    }
</script>
