import { createRouter, createWebHistory } from 'vue-router'
import {localAuthData} from '@/services/localStore'

import ContractsCreate from '../views/contracts/Create.vue'
import ContractsDetail from '../views/contracts/Detail.vue'
import ContractsList from '../views/contracts/Index.vue'
import DocumentsCreate from '../views/documents/Create.vue'
import DocumentsDetail from '../views/documents/Detail.vue'
import DocumentsList from '../views/documents/Index.vue'
import DocumentCategoryCreate from '../views/documents/categories/Create.vue'
import DocumentCategoryDetail from '../views/documents/categories/Detail.vue'
import DocumentCategoryList from '../views/documents/categories/Index.vue'
import DocumentSectionCreate from '../views/documents/sections/Create.vue'
import DocumentSectionDetail from '../views/documents/sections/Detail.vue'
import DocumentSectionList from '../views/documents/sections/Index.vue'
import DocumentSubSectionCreate from '../views/documents/sub-sections/Create.vue'
import DocumentSubSectionDetail from '../views/documents/sub-sections/Detail.vue'
import DocumentSubSectionList from '../views/documents/sub-sections/Index.vue'
import Dashboard from '../views/Dashboard.vue'
import ErrorNotFound from '../views/ErrorNotFound404.vue'
import Login from '../views/authentication/Login.vue'
import Media from '../views/media/Index.vue'
import MediaLibraryIndex from '../views/media-library/Index.vue'
import RolesCreate from '../views/roles/Create.vue'
import RolesDetail from '../views/roles/Detail.vue'
import RolesList from '../views/roles/Index.vue'
import Setting from '../views/setting/Index.vue'
import SettingDetail from '../views/setting/Detail.vue'
import SingleMediaDetail from '../views/media-library/Detail'
import SubmissionsDetail from '../views/submissions/Detail.vue'
import SubmissionsList from '../views/submissions/Index.vue'
import UsersCreate from '../views/users/Create.vue'
import UsersDetail from '../views/users/Detail.vue'
import UsersList from '../views/users/Index.vue'

const routes = [

  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: { requiresNoAuth: true }
  },
  {
    path: '/',
    name: 'dashboard',
    component: Dashboard,
    meta: { requiresAuth: true }
  },
  {
    path: '/users',
    name: 'users',
    component: UsersList,
    meta: { requiresAuth: true }
  },
  {
    path: '/users/:id',
    name: 'users-detail',
    component: UsersDetail,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/users/create',
    name: 'users-create',
    component: UsersCreate,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/contracts',
    name: 'contracts',
    component: ContractsList,
    meta: { requiresAuth: true }
  },
  {
    path: '/contracts/:id',
    name: 'contracts-detail',
    component: ContractsDetail,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/contracts/create',
    name: 'contracts-create',
    component: ContractsCreate,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/documents',
    name: 'documents',
    component: DocumentsList,
    meta: { requiresAuth: true }
  },
  {
    path: '/documents/:id',
    name: 'documents-detail',
    component: DocumentsDetail,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/documents/create',
    name: 'documents-create',
    component: DocumentsCreate,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/documents/categories',
    name: 'documentCategory',
    component: DocumentCategoryList,
    meta: { requiresAuth: true }
  },
  {
    path: '/documents/categories/:id',
    name: 'documentCategory-detail',
    component: DocumentCategoryDetail,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/documents/categories/create',
    name: 'documentCategory-create',
    component: DocumentCategoryCreate,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/documents/sections',
    name: 'documentSection',
    component: DocumentSectionList,
    meta: { requiresAuth: true }
  },
  {
    path: '/documents/sections/:id',
    name: 'documentSection-detail',
    component: DocumentSectionDetail,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/documents/sections/create',
    name: 'documentSection-create',
    component: DocumentSectionCreate,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/documents/sub-sections',
    name: 'documentSubSection',
    component: DocumentSubSectionList,
    meta: { requiresAuth: true }
  },
  {
    path: '/documents/sub-sections/:id',
    name: 'documentSubSection-detail',
    component: DocumentSubSectionDetail,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/documents/sub-sections/create',
    name: 'documentSubSection-create',
    component: DocumentSubSectionCreate,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/roles',
    name: 'roles',
    component: RolesList,
    meta: { requiresAuth: true }
  },
  {
    path: '/roles/:id',
    name: 'roles-detail',
    component: RolesDetail,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/roles/create',
    name: 'roles-create',
    component: RolesCreate,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/media',
    name: 'media',
    component: Media,
    meta: { requiresAuth: true }
  },
  {
    path: '/media-library',
    name: 'media-library-index',
    component: MediaLibraryIndex,
    meta: { requiresAuth: true }
  },
  {
    path: '/media-library/:mediaID',
    name: 'single-media-detail',
    component: SingleMediaDetail,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/setting',
    name: 'setting',
    component: Setting,
    meta: { requiresAuth: true }
  },
  {
    path: '/setting/:id',
    name: 'setting-detail',
    component: SettingDetail,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/submissions',
    name: 'submissions',
    component: SubmissionsList,
    meta: { requiresAuth: true }
  },
  {
    path: '/submissions/:id',
    name: 'submissions-detail',
    component: SubmissionsDetail,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: ErrorNotFound
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if ((localAuthData === null) && to.matched.some(record => record.meta.requiresAuth)) {
      next('/login')
  } else if ((localAuthData !== null) && to.matched.some(record => record.meta.requiresNoAuth)) {
      next('/')
  } else {
    next()
  }
})

export default router
