<template>
  <div class="users">
    <h1>Users - Create a new user</h1>
    <div class="panel">

      <form @submit.prevent="createUser">

        <BaseInput
          v-model="user.first_name"
          label="First Name"
          type="text"
          name="first_name"
         />

        <BaseInput
          v-model="user.last_name"
          label="Last Name"
          type="text"
          name="last_name"
         />

        <BaseInput
          v-model="user.email"
          label="Email"
          type="email"
          name="email"
         />

        <BaseInput
          v-model="user.password"
          label="Password"
          type="password"
          name="password"
         />
        <BaseInput
          v-model="user.password_confirmation"
          label="Confirm Password"
          type="password"
          name="password_confirmation"
          fieldClass="input-m-s"
          hideLabel
         />

        <BaseInput
          v-model="user.company"
          label="Company"
          type="text"
          name="company"
         />

        <BaseInput
          v-model="user.telephone"
          label="Telephone"
          type="text"
          name="telephone"
         />

        <BaseSelect
          :options="formOptions.countries"
          v-model="user.country"
          label="Select a country"
          name="country"
         />

        <BaseInput
          v-model="user.jobtitle"
          label="Job Role"
          name="jobtitle"
          fieldClass="input-m-s"
         />

        <BaseButton type="submit" text="Add New User" />

      </form>
    </div>
  </div>
</template>

<script>

  import { mapState } from 'vuex'
    export default {
        computed: {
          ...mapState({
            formOptions: state => state.authentication.formOptions
          })
        },
        components : {
        },
        data() {
          return {
            user: this.createFreshUserObject(),
            error: {},
            type: [
              'Admin',
              'User'
            ],

          }
        },
        created() {
          this.$store.dispatch('error/clear')
          this.$store.dispatch('authentication/fetchFormOptions', this.token)
        },
        methods: {
          createUser() {
            this.$store.dispatch('error/clear')
            this.$store.dispatch('user/createUser', this.user).then(response => {
              this.$router.push({
                name: 'users-detail',
                params: { id: response.id }
              })
              this.user = this.createFreshUserObject()
            }).catch(err => {
                this.error = err
            })
          },
          createFreshUserObject() {
            const user = this.$store.state.user
            const id = Math.floor(Math.random*10000)

            return {
              id: id,
              user: user,
              country: 'GB'
            }
          }
        }

    }
</script>
