<template v-if="!isLoading">
  <div class="card">
    <div class="card-divider"><h5>{{ setting.data[fieldKey].name }}</h5></div>
    <div class="card-section">
      <BaseInput
        v-model="setting.data[fieldKey].label"
        label="Field Label"
        name="label"
        @change="save"
      />
      <BaseInput
        v-model="setting.data[fieldKey].name"
        label="Field Name"
        name="name"
        @change="save"
      />
      <BaseSelect
        :options="fieldTypes"
        v-model="setting.data[fieldKey].type"
        name="type"
        label="Field Type"
        optionValue="value"
        optionName="name"
        @change="save"
       />
      <template v-if="setting.data[fieldKey].type === 'select'">
        <h3>Select Options</h3>
        <div>
          <div v-for="(option, optionKey) in setting.data[fieldKey].options" :key="option.value"  :option="option" class="card" >
            <div class="card-divider">
              <div><p><strong>{{ option.name }}</strong> - value: {{ option.value }}</p></div>
              <div>
                <BaseButton class="button--edit" @click="editOption(optionKey)" text="edit" />
                <BaseButton class="button--remove" @click="removeOption(optionKey)" text="remove" />
              </div>
            </div>
            <div class="card-section" v-if="isActive.includes(optionKey)" >
              <div>
                <BaseInput
                  v-model="setting.data[fieldKey].options[optionKey].value"
                  label="Option Value"
                  name="value"
                />
                <BaseInput
                  v-model="setting.data[fieldKey].options[optionKey].name"
                  label="Option Name"
                  name="name"
                />
                <BaseButton text="Save Option" @click="save" />
              </div>
            </div>
          </div>
        </div>
        <div>
          <BaseInput
            v-model="newOption.value"
            label="Option Value"
            name="value"
          />
          <BaseInput
            v-model="newOption.name"
            label="Option Name"
            name="name"
          />
          <BaseButton text="Add Option" @click="saveNewOption" />
        </div>
      </template>

      <BaseSwitch
        v-model="setting.data[fieldKey].conditional"
        :options="[{name: 'No'},{name: 'Yes'}]"
        optionName="name"
        label="Conditional"
       />

       <template v-if="setting.data[fieldKey].conditional">
          <BaseSelect
            :options="fields"
            v-model="setting.data[fieldKey].conditionalField"
            name="type"
            label="Show if the field"
            optionValue="value"
            optionName="name"
            @change="save"
           />
          <BaseSelect
            :options="fieldOptions[setting.data[fieldKey].conditionalField]"
            v-model="setting.data[fieldKey].conditionalValue"
            name="type"
            label="Is equal To"
            optionValue="value"
            optionName="name"
            @change="save"
           />
       </template>
    </div>
  </div>
</template>

<script>
    import { mapState } from 'vuex'


    export default {
      props: {
          field: {
            type: Object,
            required: true
          },
          fieldKey: {
            type: Number,
            required: true
          },
      },
      data() {
          return {
            newOption: {
              value: '',
              name: ''
            },
            isActive: [],
            fieldTypes : [{
                value: 'select',
                name: 'Select',
              },
              {
                value: 'text',
                name: 'Text Field',
              }
            ]
          }
      },
      computed: {
        ...mapState({
          setting: state => state.setting.setting,
          isLoading: state => state.setting.isLoading,
        }),
        fields() {
          var data = [];

          if (this.setting.data.length) {

            this.setting.data.forEach((field) => {
              console.log(field)
              data.push({ value: field.name, name: field.label })
            })

            console.log(data)
          }

          return data
        },
        fieldOptions() {
          var data = [];
          this.setting.data.forEach((field) => {
            data[field.name] = field.options
          })

          return data
        }
      },
      methods: {
        save() {
          this.$store.dispatch('error/clear')
          this.$store.dispatch('setting/updateSetting', this.setting)
        },
        saveNewOption() {
          if (this.setting.data[this.fieldKey].options === null) {
            this.setting.data[this.fieldKey].options = []
          }
          this.setting.data[this.fieldKey].options.push(this.newOption)
          this.save()


          this.newOption = {
              value: '',
              name: ''
            }
        },
        removeOption(key) {
          this.setting.data[this.fieldKey].options.splice(key, 1)
          this.save()
        },
        editOption(key) {
          const index = this.isActive.indexOf(key);
          if (index > -1) {
            this.isActive.splice(index, 1);
          } else {
            this.isActive.push(key);
          }

        }
      }
    }
</script>

<style scoped lang="scss">
  .field-group {
    border-bottom: 1px #000 solid;
    padding: 10px 0;

    &:last-of-type {
      border-bottom: 0;
    }
  }

  .card-divider {
    display: flex;
    justify-content: space-between;
  }
</style>
