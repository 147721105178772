<template>
  <div class="documentCategory">
    <h1>Document Category - Create a new category</h1>
    <div>
      <form @submit.prevent="createDocumentCategory">

        <BaseInput
          v-model="documentCategory.name"
          label="Name"
          type="text"
          name="name"
         />

        <BaseButton type="submit" text="Add New Document" />

      </form>
    </div>
  </div>
</template>

<script>
    // import Datepicker from 'vue3-datepicker'

    export default {
        data() {
          return {
            documentCategory: this.createFreshDocumentObject(),
            error: {},

          }
        },
        methods: {
          createDocumentCategory() {
            this.$store.dispatch('error/clear')
            this.$store.dispatch('documentCategory/createDocumentCategory', this.documentCategory).then(response => {
              this.$router.push({
                name: 'documentCategory-detail',
                params: { id: response.id }
              })
              this.documentCategory = this.createFreshDocumentObject()
            }).catch(err => {
                this.error = err
            })
          },
          createFreshDocumentObject() {
            const documentCategory = this.$store.state.documentCategory
            const id = Math.floor(Math.random*10000)

            return {
              id: id,
              documentCategory: documentCategory
            }
          }
        }

    }
</script>
