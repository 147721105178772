<template>
  <div class="documentSection">
    <h1>Document Section - Create a new section</h1>
    <div>
      <form @submit.prevent="createDocumentSection">

        <BaseInput
          v-model="documentSection.name"
          label="Name"
          type="text"
          name="name"
         />

        <BaseButton type="submit" text="Add New Section" />

      </form>
    </div>
  </div>
</template>

<script>
    // import Datepicker from 'vue3-datepicker'

    export default {
        data() {
          return {
            documentSection: this.createFreshDocumentObject(),
            error: {},

          }
        },
        methods: {
          createDocumentSection() {
            this.$store.dispatch('error/clear')
            this.$store.dispatch('documentSection/createDocumentSection', this.documentSection).then(response => {
              this.$router.push({
                name: 'documentSection-detail',
                params: { id: response.id }
              })
              this.documentSection = this.createFreshDocumentObject()
            }).catch(err => {
                this.error = err
            })
          },
          createFreshDocumentObject() {
            const documentSection = this.$store.state.documentSection
            const id = Math.floor(Math.random*10000)

            return {
              id: id,
              documentSection: documentSection
            }
          }
        }

    }
</script>
