<template>
        <div class="loop-list">
            <div class="loop-list__details">
                <router-link :to="{ name: route, params: { id: item.id }}">
                    <slot name="details">
                        <p><strong>{{ item[itemName] }}</strong></p>
                        <p v-if="type"><span>Type: {{ item.type.title }}</span></p>
                        <p v-if="updated"><span>Last updated: {{ new Date(item.updated_at).toUTCString() }}</span></p>
                    </slot>
                </router-link>
                <div>
                    <slot name="buttons">
                        <router-link :to="{ name: route, params: { id: item.id }}"><BaseButton text="View" /></router-link>
                        <BaseButton v-if="duplicateShow" text="Duplicate" class="buton button--outline" @click="duplicateLocal"/>
                    </slot>
                </div>
            </div>
            <template v-if="item.children !== undefined && item.children.length > 0 && draggable">
                <draggable class="dragArea list-group w-full" :list="item.children" @change="log(item.children)">
                    <ListLoop v-for="(subItem) in item.children" :key="subItem.id" :item="subItem" :route="subRoute" :itemName="itemName" />
                </draggable>
            </template>
            <template v-else-if="item.children !== undefined && item.children.length > 0">
                <ListLoop v-for="(subItem) in item.children" :key="subItem.id" :item="subItem" :route="subRoute" :itemName="itemName" />
            </template>
        </div>
</template>
<script>

    import { VueDraggableNext } from 'vue-draggable-next'

    export default {
        name: 'ListLoop',
        data() {
            return {
                enabled: true,
                dragging: false
            }
        },
        components: {
          draggable: VueDraggableNext,
        },
        props: {
            item: {
                type: Object,
                required: true
            },
            route: {
                type: String,
                required: true
            },
            subRoute: {
                type: String,
                default: ''
            },
            itemName: {
                type: String,
                required: true
            },
            duplicate: {
                type: [Function],
                default: () => {}
            },
            duplicateShow: {
                type: Boolean,
                default: false
            },
            type: {
                type: Boolean,
                default: false
            },
            updated: {
                type: Boolean,
                default: false
            },
            draggable: {
                type: Boolean,
                default: false
            },
            draggableLog: {
                type: [Boolean, Function],
                default: false
            }
        },
        methods: {
            duplicateLocal() {
                this.duplicate(this.item.id)
                return false
            },
            log(event) {
                this.draggableLog(event)
            },
        }
    }
</script>
<style scoped>

</style>
