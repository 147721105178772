import DocumentService from '@/services/DocumentService.js'

export const namespaced = true

export const state = {
        documents: [],
        documentsTotal: 0,
        document: {},
        template: {
            contracts: []
        },
        isLoading: true,
        meta: {},
    }
export const mutations = {
    NEW(state, template) {
        state.template = template
    },
    ADD(state, document) {
        state.documents.push(document)
    },
    UPDATE(state, document) {
        state.document = document
    },
    DELETE(state, index) {
        state.documents.splice(index)
    },
    SET_LIST(state, documents) {
        state.documents = documents
    },
    SET_META(state, meta) {
        state.meta = meta
    },
    SET_LIST_TOTAL(state, total) {
        state.documentsTotal = total
    },
    SET_ITEM(state, document) {
        state.document = document
    },
    SET_IS_LOADING(state, isLoading) {
        state.isLoading = isLoading
    }
  }

export const actions = {
    newDocument({ commit, dispatch}) {

        return DocumentService.newDocument().then(response => {
            commit('NEW', response.data.data)
        }).catch(error => {
            const notification = {
                type: 'error',
                message: error.response.data.message
            }
            dispatch('notification/add', notification, {root: true})

            const errors = error.response.data.errors
            Object.keys(errors).forEach(key => {
                dispatch('error/add', {key, message: errors[key]}, {root: true})
            });

            throw error
        })
    },
    createDocument({ commit, dispatch}, document) {

        return DocumentService.postDocument(document).then(response => {
            commit('ADD', document)
            const notification = {
                type: 'success',
                message: 'New document has been created!'
            }
            dispatch('notification/add', notification, {root: true})

            return response.data.data
        }).catch(error => {
            const notification = {
                type: 'error',
                message: error.response.data.message
            }
            dispatch('notification/add', notification, {root: true})

            const errors = error.response.data.errors
            Object.keys(errors).forEach(key => {
                dispatch('error/add', {key, message: errors[key]}, {root: true})
            });

            throw error
        })
    },
    updateDocument({ commit, dispatch}, document) {

        return DocumentService.updateDocument(document).then(response => {
            commit('UPDATE', response.data.data)

            const notification = {
                type: 'success',
                message: 'Document has been updated!'
            }
            dispatch('notification/add', notification, {root: true})


        }).catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem updating the document: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})

            const errors = error.response.data.errors
            Object.keys(errors).forEach(key => {
                dispatch('error/add', {key, message: errors[key]}, {root: true})
            });

            throw error
        })
    },
    deleteDocument({ commit, dispatch}, id) {

      const document = getters.getDocumentById(id)
      const documentIndex = state.documents.indexOf(document);

        return DocumentService.deleteDocument(id).then(() => {
            commit('DELETE', documentIndex)

            const notification = {
                type: 'success',
                message: 'Document has been deleted!'
            }
            dispatch('notification/add', notification, {root: true})
        }).catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem deleting the document: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})
            throw error
        })
    },
    fetchDocuments({commit, dispatch}, filters) {
      DocumentService.getDocuments(filters)
      .then(response => {
        commit('SET_LIST', response.data.data)
        commit('SET_META', response.data.meta)
        if(response.data.meta !== undefined) {
            commit('SET_LIST_TOTAL', response.data.meta.total)
        }
        commit('SET_IS_LOADING', false)
      })
      .catch(error => {
        const notification = {
            type: 'error',
            message: 'There was a problem getting the document: ' + error.message
        }
        dispatch('notification/add', notification, {root: true})
      })
    },
    fetchDocument({commit, dispatch}, id) {

        DocumentService.getDocument(id)
        .then(response => {
          commit('SET_ITEM', response.data.data)
          commit('SET_IS_LOADING', false)
        })
        .catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem getting a document: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})
        })

    }
  }

export const getters = {
    getDocumentById: state => id => {
      return state.documents.find(document => document.id == id)
    }
  }
