import ContractService from '@/services/ContractService.js'

export const namespaced = true

export const state = {
        contracts: [],
        contractsTotal: 0,
        contract: {},
        isLoading: true,
        meta: {},
    }
export const mutations = {
    ADD(state, contract) {
        state.contracts.push(contract)
    },
    UPDATE(state, contract) {
        state.contract = contract
    },
    DELETE(state, index) {
        state.contracts.splice(index)
    },
    SET_LIST(state, contracts) {
        state.contracts = contracts
    },
    SET_META(state, meta) {
        state.meta = meta
    },
    SET_LIST_TOTAL(state, total) {
        state.contractsTotal = total
    },
    SET_ITEM(state, contract) {
        state.contract = contract
    },
    SET_IS_LOADING(state, isLoading) {
        state.isLoading = isLoading
    }
  }

export const actions = {
    createContract({ commit, dispatch}, contract) {

        return ContractService.postContract(contract).then(response => {
            commit('ADD', contract)
            const notification = {
                type: 'success',
                message: 'New type has been created!'
            }
            dispatch('notification/add', notification, {root: true})

            return response.data.data
        }).catch(error => {
            const notification = {
                type: 'error',
                message: error.response.data.message
            }
            dispatch('notification/add', notification, {root: true})

            const errors = error.response.data.errors
            Object.keys(errors).forEach(key => {
                dispatch('error/add', {key, message: errors[key]}, {root: true})
            });

            throw error
        })
    },
    updateContract({ commit, dispatch}, contract) {

        return ContractService.updateContract(contract).then(response => {
            commit('UPDATE', response.data.data)

            const notification = {
                type: 'success',
                message: 'Contract has been updated!'
            }
            dispatch('notification/add', notification, {root: true})


        }).catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem updating the contract: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})

            const errors = error.response.data.errors
            Object.keys(errors).forEach(key => {
                dispatch('error/add', {key, message: errors[key]}, {root: true})
            });

            throw error
        })
    },
    deleteContract({ commit, dispatch}, id) {

      const contract = getters.getContractById(id)
      const contractIndex = state.contracts.indexOf(contract);

        return ContractService.deleteContract(id).then(() => {
            commit('DELETE', contractIndex)

            const notification = {
                type: 'success',
                message: 'Type has been deleted!'
            }
            dispatch('notification/add', notification, {root: true})
        }).catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem deleting the contract: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})
            throw error
        })
    },
    fetchContracts({commit, dispatch}, filters) {
      ContractService.getContracts(filters)
      .then(response => {
        commit('SET_LIST', response.data.data)
        commit('SET_META', response.data.meta)
        if(response.data.meta !== undefined) {
            commit('SET_LIST_TOTAL', response.data.meta.total)
        }
        commit('SET_IS_LOADING', false)
      })
      .catch(error => {
        const notification = {
            type: 'error',
            message: 'There was a problem getting the contract: ' + error.message
        }
        dispatch('notification/add', notification, {root: true})
      })
    },
    fetchContract({commit, dispatch}, id) {

        ContractService.getContract(id)
        .then(response => {
          commit('SET_ITEM', response.data.data)
          commit('SET_IS_LOADING', false)
        })
        .catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem getting a contract: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})
        })

    }
  }

export const getters = {
    getContractById: state => id => {
      return state.contracts.find(contract => contract.id == id)
    }
  }
