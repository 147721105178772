<template>
    <label :for="name" :class="fieldErrorClass" >{{ label}}</label>
    <ckeditor :editor="editor" v-model="editorData" :config="editorConfig" @input="saveChanges" ></ckeditor>

    <!-- <textarea
        v-bind="$attrs"
        :placeholder="label"
        :class="fieldErrorClass"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
    /> -->
    <template v-if="errors.length > 0">
      <span class="message message--error" v-for="(message, keyMessage) in fieldErrors" :key="keyMessage">{{ message }}</span>
    </template>
</template>

<script>

import { mapState } from 'vuex'

import CKEditor from '@ckeditor/ckeditor5-vue';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
    data() {
        return {
            editor: ClassicEditor,
            editorData: this.modelValue,
            editorConfig: {
                // The configuration of the editor.
                toolbar: [ 'heading', '|', 'bold', 'italic', '|', 'numberedList', 'bulletedList', 'link', '|', 'undo', 'redo' ]
            }
        };
    },
    components: {
        // Use the <ckeditor> component in this view.
        ckeditor: CKEditor.component
    },
    props: {
        label: {
            type: String,
            default: ''
        },
        modelValue: {
            type: [String, Number],
            default: ''
        },
        name: {
            type: String,
            default: ''
        },
        save: {
            type: Function,
            default: () => {}
        }
    },
    methods: {
        saveChanges() {
            this.$emit('update:modelValue', this.editorData)
            this.save()
        },
    },
    computed: {
        ...mapState('error', ['errors']),
        fieldErrorClass() {
            if (this.errors.length > 0) {
                if (this.errors.find(error => error.key == this.name)) {
                    return 'error';
                }
            }
            return ''
        },
        fieldErrors() {
            if (this.errors.length > 0) {
                if (this.errors.find(error => error.key == this.name)) {
                    if (this.errors.find(error => error.key == this.name).message.length > 0) {
                        return this.errors.find(error => error.key == this.name).message
                    }
                }
            }
            return []
        },

    }
}
</script>

<style scoped>

</style>
