import FormService from '@/services/FormService.js'

export const namespaced = true

export const state = {
        forms: [],
        formsTotal: 0,
        form: {},
        isLoading: true,
        meta: {},
    }
export const mutations = {
    ADD(state, form) {
        state.forms.push(form)
    },
    UPDATE(state, form) {
        state.form = form
    },
    DELETE(state, index) {
        state.forms.splice(index)
    },
    SET_LIST(state, forms) {
        state.forms = forms
    },
    SET_META(state, meta) {
        state.meta = meta
    },
    SET_LIST_TOTAL(state, total) {
        state.formsTotal = total
    },
    SET_ITEM(state, form) {
        state.form = form
    },
    SET_IS_LOADING(state, isLoading) {
        state.isLoading = isLoading
    }
  }

export const actions = {
    createForm({ commit, dispatch}, form) {

        return FormService.postForm(form).then(response => {
            commit('ADD', form)
            const notification = {
                type: 'success',
                message: 'New form has been created!'
            }
            dispatch('notification/add', notification, {root: true})

            return response.data.data
        }).catch(error => {
            const notification = {
                type: 'error',
                message: error.response.data.message
            }
            dispatch('notification/add', notification, {root: true})

            const errors = error.response.data.errors
            Object.keys(errors).forEach(key => {
                dispatch('error/add', {key, message: errors[key]}, {root: true})
            });

            throw error
        })
    },
    updateForm({ commit, dispatch}, form) {

        return FormService.updateForm(form).then(response => {
            commit('UPDATE', response.data.data)

            const notification = {
                type: 'success',
                message: 'Form has been updated!'
            }
            dispatch('notification/add', notification, {root: true})


        }).catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem updating the form: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})

            const errors = error.response.data.errors
            Object.keys(errors).forEach(key => {
                dispatch('error/add', {key, message: errors[key]}, {root: true})
            });

            throw error
        })
    },
    deleteForm({ commit, dispatch}, id) {

      const form = getters.getFormById(id)
      const formIndex = state.forms.indexOf(form);

        return FormService.deleteForm(id).then(() => {
            commit('DELETE', formIndex)

            const notification = {
                type: 'success',
                message: 'Form has been deleted!'
            }
            dispatch('notification/add', notification, {root: true})
        }).catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem deleting the form: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})
            throw error
        })
    },
    fetchForms({commit, dispatch}, filters) {
      FormService.getForms(filters)
      .then(response => {
        commit('SET_LIST', response.data.data)
        commit('SET_META', response.data.meta)
        if(response.data.meta !== undefined) {
            commit('SET_LIST_TOTAL', response.data.meta.total)
        }
        commit('SET_IS_LOADING', false)
      })
      .catch(error => {
        const notification = {
            type: 'error',
            message: 'There was a problem getting the form: ' + error.message
        }
        dispatch('notification/add', notification, {root: true})
      })
    },
    fetchForm({commit, dispatch}, id) {

        FormService.getForm(id)
        .then(response => {
          commit('SET_ITEM', response.data.data)
          commit('SET_IS_LOADING', false)
        })
        .catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem getting a form: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})
        })

    }
  }

export const getters = {
    getFormById: state => id => {
      return state.forms.find(form => form.id == id)
    }
  }
