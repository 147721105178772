import SubmissionUserService from '@/services/SubmissionUserService.js'

export const namespaced = true

export const state = {
        submissionUsers: [],
        submissionUsersTotal: 0,
        submissionUser: {},
        isLoading: true,
        meta: {},
    }
export const mutations = {
    ADD(state, submissionUser) {
        state.submissionUsers.push(submissionUser)
    },
    UPDATE(state, submissionUser) {
        state.submissionUser = submissionUser
    },
    DELETE(state, index) {
        state.submissionUsers.splice(index)
    },
    SET_LIST(state, submissionUsers) {
        state.submissionUsers = submissionUsers
    },
    SET_META(state, meta) {
        state.meta = meta
    },
    SET_LIST_TOTAL(state, total) {
        state.submissionUsersTotal = total
    },
    SET_ITEM(state, submissionUser) {
        state.submissionUser = submissionUser
    },
    SET_IS_LOADING(state, isLoading) {
        state.isLoading = isLoading
    }
  }

export const actions = {
    createSubmissionUser({ commit, dispatch}, submissionUser) {

        return SubmissionUserService.postSubmissionUser(submissionUser).then(response => {
            commit('ADD', submissionUser)
            const notification = {
                type: 'success',
                message: 'New submission user has been created!'
            }
            dispatch('notification/add', notification, {root: true})

            return response.data.data
        }).catch(error => {
            const notification = {
                type: 'error',
                message: error.response.data.message
            }
            dispatch('notification/add', notification, {root: true})

            const errors = error.response.data.errors
            Object.keys(errors).forEach(key => {
                dispatch('error/add', {key, message: errors[key]}, {root: true})
            });

            throw error
        })
    },
    updateSubmissionUser({ commit, dispatch}, submissionUser) {

        return SubmissionUserService.updateSubmissionUser(submissionUser).then(response => {
            commit('UPDATE', response.data.data)

            const notification = {
                type: 'success',
                message: 'submission user has been updated!'
            }
            dispatch('notification/add', notification, {root: true})


        }).catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem updating the submission user: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})

            const errors = error.response.data.errors
            Object.keys(errors).forEach(key => {
                dispatch('error/add', {key, message: errors[key]}, {root: true})
            });

            throw error
        })
    },
    deleteSubmissionUser({ commit, dispatch}, id) {

      const submissionUser = getters.getSubmissionUserById(id)
      const submissionUserIndex = state.submissionUsers.indexOf(submissionUser);

        return SubmissionUserService.deleteSubmissionUser(id).then(() => {
            commit('DELETE', submissionUserIndex)

            const notification = {
                type: 'success',
                message: 'submission user has been deleted!'
            }
            dispatch('notification/add', notification, {root: true})
        }).catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem deleting the submission user: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})
            throw error
        })
    },
    fetchSubmissionUsers({commit, dispatch}, filters) {
      SubmissionUserService.getSubmissionUsers(filters)
      .then(response => {
        commit('SET_LIST', response.data.data)
        commit('SET_META', response.data.meta)
        if(response.data.meta !== undefined) {
            commit('SET_LIST_TOTAL', response.data.meta.total)
        }
        commit('SET_IS_LOADING', false)
      })
      .catch(error => {
        const notification = {
            type: 'error',
            message: 'There was a problem getting the submission user: ' + error.message
        }
        dispatch('notification/add', notification, {root: true})
      })
    },
    fetchSubmissionUser({commit, dispatch}, id) {

        SubmissionUserService.getSubmissionUser(id)
        .then(response => {
          commit('SET_ITEM', response.data.data)
          commit('SET_IS_LOADING', false)
        })
        .catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem getting a submission user: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})
        })

    }
  }

export const getters = {
    getSubmissionUserById: state => id => {
      return state.submissionUsers.find(submissionUser => submissionUser.id == id)
    }
  }
