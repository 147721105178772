<template>
  <div class="auto-complete" v-if="options.length">
    <div v-if="!isMultiple">
      <input v-if="!value.id" v-model="textSearch" type="text" :placeholder="placeholder" @keyUp="showOptions"/>
      <div class="auto-complete__selected" v-if="value.id">
        <span class="tag">
          {{value[subTitle]}} <BaseButton class="button button--remove" @click.prevent="clearItem" text="Remove" />
        </span>
      </div>
      <ul class="options" v-show="isShow">
        <li v-for="item in filteredOptions" :key="item" @click="selectItem(item)">
          <slot :item="item">
            {{item[subTitle]}}
            <strong v-if="subStatus && item.status" class="success badge badge--lozenge " >Live</strong>
            <strong v-if="subStatus && !item.status" class="warning badge badge--lozenge " >Inactive</strong>
          </slot>
        </li>
        <li v-if="filteredOptions.length===0">Item not found</li>
      </ul>
    </div>
    <div v-if="isMultiple">
      <input v-model="textSearch" type="text" :placeholder="placeholder" @focus="showOptions"/>
      <div class="auto-complete__selected">
        <div class="auto-complete__selected__item" v-for="(v, k) in value" :key="k">
          {{v[subTitle]}} <BaseButton class="button button--remove"  @click.prevent="clearItem" />
        </div>
      </div>
      <ul class="options" v-show="isShow">
        <li v-for="item in filteredOptions" :key="item" :class="{'disabled': inSelectedItems(item.id)}" @click="addItem(item)">
          <slot :item="item">
            {{item[subTitle]}}
            <strong v-if="subStatus && item.status" class="success badge badge--lozenge " >Live</strong>
            <strong v-if="subStatus && !item.status" class="warning badge badge--lozenge " >Inactive</strong>
          </slot>
        </li>
        <li v-if="filteredOptions.length===0">Item not found</li>
      </ul>
    </div>
  </div>
</template>
<script>

export default {
  props: {
    isMultiple: {
      type: Boolean,
      default: false,
    },
    max: {
      type: Number,
      default: 3,
    },
    placeholder: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default() {
        return [];
      },
    },
    modelValue: {
      type: [Object, Array],
      default() {
        return {
          id: undefined,
          title: undefined,
        };
      },
    },
    subTitle: {
      type: String,
      default: 'title'
    },
    subStatus: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      textSearch: '',
      isShow: false,
    };
  },
  computed: {
    filteredOptions() {
      return this.options.filter(val => val[this.subTitle].toLowerCase().includes(this.textSearch.toLowerCase()));
    },
    selectedItems() {
      if (this.isMultiple) {
        return this.value.map(v => v.id);
      }
      return [];
    },
    value() {
      return this.modelValue !== null ? this.modelValue : { id: undefined, title: undefined }
    }
  },
  methods: {
    inSelectedItems(id) {
      return this.selectedItems.includes(id);
    },
    showOptions() {
      if (this.textSearch.length > 1) {
        this.isShow = true;
      } else {
        this.isShow = false;
      }
    },
    selectItem(item) {
      this.textSearch = '';
      this.isShow = false;
      this.$emit('update:modelValue', item)
    },
    clearItem() {
      this.textSearch = '';
      this.isShow = false;
      this.$emit('update:modelValue', {})
    },
    addItem(item) {
      if (!this.inSelectedItems(item.id)) {
        this.textSearch = '';
        this.isShow = false;
        const items = JSON.parse(JSON.stringify(this.value));
        items.push(item);
      }
    },
    removeItem(index) {
      this.textSearch = '';
      const items = JSON.parse(JSON.stringify(this.value));
      items.splice(index, 1);
    },
  }
}

</script>
