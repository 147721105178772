<template>
  <div class="documents">
    <h1>Documents : {{ document.name }}</h1>
    <div>
    <p v-if="isLoading">Loading...</p>
    <template v-else >

      <div class="grid-x grid-margin-x" >
        <div class="panel cell medium-8">
          <h3>Document Details</h3>
          <BaseButton @click="previewDocument"  text="Preview" />

          <button class="button" @click="edit">Edit</button>
          <template v-if="!isEditing">
            <p><strong>{{ document.name }}</strong></p>
            <p class="margin">Created: <strong class="info badge badge--lozenge">{{ document.created_at }}</strong></p>
          </template>
          <template v-else>
            <form @submit.prevent="update">

              <BaseInput
                v-model="document.name"
                label="Name"
                type="text"
                name="name"
               />

              <BaseSelect
                :options="contracts"
                v-model="document.contract_id"
                label="Select contract"
                optionValue="id"
                optionName="name"
               />

              <BaseButton type="submit" text="Update" />
          </form>
          </template>

        </div>
        <div class="panel cell medium-4">
          <p>Click below to delete this document.</p>
          <template v-if="!document.protected">
            <BaseButton text="Delete" @click="remove" class="button button--danger" />
          </template>
          <template v-else>
            <p>This document can not be removed</p>
          </template>
        </div>

      </div>
      <div class="grid-x grid-margin-x" >
        <div class="panel cell medium-12">
          <h3 class="no-margin">Document Structure</h3>
          <p>Below is the structure of this document. The hierarchy goes: Category > Section > Sub Section.</p>
          <p>Categories are used to group sections together like subject/departments sections.</p>


          <form @submit.prevent="createCategory" class="form-min panel grid-x grid-margin-x">
            <BaseInput
              v-model="newCategory.name"
              label="New Category Title"
             />
            <BaseButton text="Add" />
          </form>

          <form @submit.prevent="createSection" class="form-min panel grid-x grid-margin-x">
            <div class="cell medium-4 large-3">
              <BaseInput
                v-model="newSection.name"
                label="New Section Title"
               />
            </div>
            <div class="cell medium-4 large-3">
                <BaseSelect
                :options="categories"
                v-model="newSection.category_id"
                label="Select Category"
                optionValue="id"
                optionName="name"
               />
            </div>
            <BaseButton text="Add" />
          </form>


          <template v-if="categories.length">
            <ListLoop v-for="(item) in categories" :key="item.id" :item="item" route="documentCategory-detail" subRoute="documentSection-detail" itemName="name" >
              <template v-slot:details>
                <h3><strong>{{ item.name }}</strong></h3>
              </template>
            </ListLoop>
          </template>
          <p v-else class="warning badge badge--lozenge">No categories in this document yet!</p>
        </div>
      </div>

    </template>
    </div>
  </div>
</template>

<script>
    import axios from 'axios'
    import ListLoop from '@/components/ListLoop.vue'
    import { mapState } from 'vuex'

    export default {
        props: ["id"],
        data() {
          return {
            isEditing: false,
            addNewCategory: false,
            addNewSection: false,
            newCategory: this.createFreshCategoryObject(),
            newSection: this.createFreshSectionObject(),
            newSubSection: this.createFreshSubSectionObject(),
            newFile: this.createFreshFileObject()
          }
        },
        components : {
          ListLoop
        },
        created() {
          this.$store.dispatch('error/clear')
          this.$store.dispatch('document/fetchDocument', this.id)

        },
        computed: {
          isLoading() {
            return this.document.isLoading
          },
          isDisabled() {
            return false
          },
          ...mapState({
            document: state => state.document.document
          }),
          categories() {
            return (this.document.categories) ? this.document.categories : []
          },
          sections() {
            return (this.document.sections) ? this.document.sections : []
          },
          contracts() {
            return this.document.contracts || {}
          }
        },
        methods: {
          remove() {
            this.$store.dispatch('document/deleteDocument', this.id)
          },
          update() {
            this.$store.dispatch('error/clear')
            this.$store.dispatch('document/updateDocument', this.document)
            this.isEditing = false
          },
          edit() {
            this.isEditing = (this.isEditing) ? false : true

          },
          createCategory() {
            console.log('create new category');

            this.newCategory.document_id = this.document.id;
            this.newCategory.contract_id = this.document.contract_id;

            this.$store.dispatch('error/clear')
            this.$store.dispatch('documentCategory/createDocumentCategory', this.newCategory).then(() => {
              this.$store.dispatch('document/fetchDocument', this.id)
              this.newCategory = this.createFreshCategoryObject()
            }).catch(err => {
                this.error = err
            })
          },
          createSection() {
            console.log('create new section');

            this.newSection.document_id = this.document.id;
            this.newSection.contract_id = this.document.contract_id;

            this.$store.dispatch('error/clear')
            this.$store.dispatch('documentSection/createDocumentSection', this.newSection).then(() => {
              this.$store.dispatch('document/fetchDocument', this.id)
              this.newSection = this.createFreshSectionObject()
            }).catch(err => {
                this.error = err
            })
          },
          createFile() {

          },
          createFreshCategoryObject() {

            return {
              name: '',
            }
          },
          createFreshSectionObject() {

            return {
              name: '',
            }
          },
          createFreshSubSectionObject() {

            return {
              name: '',
            }
          },
          createFreshFileObject() {

            return {
              name: '',
            }
          },

          toggleAddNewCategory() {
            this.addNewCategory = this.addNewCategory ? false : true
          },

          toggleAddNewSection() {
            this.addNewSection = this.addNewSection ? false : true
          },

          previewDocument() {

            let formData = new FormData();

            formData.append('contract_id', this.document.contract_id)
            formData.append('document_id', this.document.id)


            axios.post(process.env.VUE_APP_API_URL + 'submission', formData)
            .then((response) => {
              console.log(response)
            })
            .catch((error) => {
              console.log(error)
            })
          }
        }

    }
</script>
