import DocumentSectionService from '@/services/DocumentSectionService.js'

export const namespaced = true

export const state = {
        documentSections: [],
        documentSectionsTotal: 0,
        documentSection: {},
        isLoading: true,
        meta: {},
    }
export const mutations = {
    ADD(state, documentSection) {
        state.documentSections.push(documentSection)
    },
    UPDATE(state, documentSection) {
        state.documentSection = documentSection
    },
    DELETE(state, index) {
        state.documentSections.splice(index)
    },
    SET_LIST(state, documentSections) {
        state.documentSections = documentSections
    },
    SET_META(state, meta) {
        state.meta = meta
    },
    SET_LIST_TOTAL(state, total) {
        state.documentSectionsTotal = total
    },
    SET_ITEM(state, documentSection) {
        state.documentSection = documentSection
    },
    SET_IS_LOADING(state, isLoading) {
        state.isLoading = isLoading
    }
  }

export const actions = {
    createDocumentSection({ commit, dispatch}, documentSection) {

        return DocumentSectionService.postDocumentSection(documentSection).then(response => {
            commit('ADD', documentSection)
            const notification = {
                type: 'success',
                message: 'New section has been created!'
            }
            dispatch('notification/add', notification, {root: true})

            return response.data.data
        }).catch(error => {
            const notification = {
                type: 'error',
                message: error.response.data.message
            }
            dispatch('notification/add', notification, {root: true})

            const errors = error.response.data.errors
            Object.keys(errors).forEach(key => {
                dispatch('error/add', {key, message: errors[key]}, {root: true})
            });

            throw error
        })
    },
    updateDocumentSection({ commit, dispatch}, documentSection) {

        return DocumentSectionService.updateDocumentSection(documentSection).then(response => {
            commit('UPDATE', response.data.data)

            const notification = {
                type: 'success',
                message: 'Section has been updated!'
            }
            dispatch('notification/add', notification, {root: true})


        }).catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem updating the section: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})

            const errors = error.response.data.errors
            Object.keys(errors).forEach(key => {
                dispatch('error/add', {key, message: errors[key]}, {root: true})
            });

            throw error
        })
    },
    deleteDocumentSection({ commit, dispatch}, id) {

      const documentSection = getters.getDocumentSectionById(id)
      const documentSectionIndex = state.documentSections.indexOf(documentSection);

        return DocumentSectionService.deleteDocumentSection(id).then(() => {
            commit('DELETE', documentSectionIndex)

            const notification = {
                type: 'success',
                message: 'Section has been deleted!'
            }
            dispatch('notification/add', notification, {root: true})
        }).catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem deleting the section: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})
            throw error
        })
    },
    fetchDocumentSections({commit, dispatch}, filters) {
      DocumentSectionService.getDocumentSections(filters)
      .then(response => {
        commit('SET_LIST', response.data.data)
        commit('SET_META', response.data.meta)
        if(response.data.meta !== undefined) {
            commit('SET_LIST_TOTAL', response.data.meta.total)
        }
        commit('SET_IS_LOADING', false)
      })
      .catch(error => {
        const notification = {
            type: 'error',
            message: 'There was a problem getting the section: ' + error.message
        }
        dispatch('notification/add', notification, {root: true})
      })
    },
    fetchDocumentSection({commit, dispatch}, id) {

        DocumentSectionService.getDocumentSection(id)
        .then(response => {
          commit('SET_ITEM', response.data.data)
          commit('SET_IS_LOADING', false)
        })
        .catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem getting a section: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})
        })

    }
  }

export const getters = {
    getDocumentSectionById: state => id => {
      return state.documentSections.find(documentSection => documentSection.id == id)
    }
  }
