<template>
  <div class="submissions">
    <h1>Submissions</h1>
    <template v-if="!isFilterActive">
      <BaseButton text="Filter" @click="filterToggle" />
    </template>
    <div v-else class="card">
      <div class="card-divider" style="justify-content: space-between;">
        <h3 class="no-margin">Filter</h3>
        <BaseButton text="X" class="button button--close" @click="filterToggle" />
      </div>
      <div class="card-section gid-container">
        <form @submit.prevent="filterResults" class="grid-x grid-margin-x">
          <div class="cell medium-4 large-3">
            <BaseInput
              v-model="filter.q"
              label="Search"
              type="search"
              name="q"
             />
          </div>
          <div class="cell medium-4 large-3">
            <BaseSelect
              :options="submission.meta.filters.contract || []"
              v-model="filter.contract"
              label="Select a contract"
              optionValue="id"
              optionName="name"
             />
          </div>
          <div class="cell medium-4 large-3">
            <BaseSelect
              :options="submission.meta.filters.document || []"
              v-model="filter.document"
              label="Select a document"
              optionValue="id"
              optionName="name"
             />
          </div>
          <div class="cell medium-4 large-3">
            <BaseDate
              v-model="filter.created_from"
              label="Submitted from"
             />
          </div>
          <div class="cell medium-4 large-3">
            <BaseDate
              v-model="filter.created_until"
              label="Submitted until"
             />
          </div>

          <div class="cell medium-4 large-3">
            <BaseButton type="submit" text="Filter" style="margin-top: 27px;" />
          </div>
        </form>
      </div>
    </div>
    <BaseButton text="Export CSV" class="button--outline" @click="exportCSV" :disabled="exporting" :class="{'active' : exporting}" />

    <p v-if="isLoading">Loading...</p>
    <template v-else >
      <div>
        <ListLoop v-for="(item) in submission.submissions" :key="item.id" :item="item" route="submissions-detail" itemName="created_at" />
      </div>
      <Pagination :page="page" :perPage="perPage" :filter="filter" routeName="submissions" :total="totalSubmissions" />

    </template>

  </div>
</template>

<script>
    import ListLoop from '@/components/ListLoop.vue'
    import Pagination from '@/components/Pagination.vue'
    import { mapState } from 'vuex'

    export default {
        data() {
          return {
            isFilterActive: false,
            exporting: false,
            filter: {
              q: this.$route.query.q || '',
              perPage: 10,
              page: parseInt(this.$route.query.page) || 1,
              contract: this.$route.query.contract || 'all',
              document: this.$route.query.document || 'all',
              created_from: this.$route.query.created_from || '',
              created_until: this.$route.query.created_until || '',
            }
          }
        },
        components: {
          ListLoop,
          Pagination
        },
        created() {
          this.$store.dispatch('submission/fetchSubmissions', this.filter)
        },
        computed: {
          perPage(){
            return this.filter.perPage;
          },
          page() {
            return this.filter.page;
          },
          totalSubmissions() {
            return this.submission.submissionsTotal
          },
          isLoading() {
            return this.submission.isLoading
          },
          ...mapState(['submission'])
        },
        methods : {
          filterToggle() {
            this.isFilterActive = (this.isFilterActive) ? false : true
          },
          filterResults() {
            this.$store.dispatch('submission/fetchSubmissions', this.filter)
          },
          exportCSV() {
            this.exporting = true;
            this.$store.dispatch('submission/exportSubmissions', this.filter).then(() => {
              this.exporting = false;
            })
          }
        }

    }
</script>
