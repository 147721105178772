import apiClient from './apiClient.js'


export default {
    exportSubmissions(filters) {
        let filtersEncoded = new URLSearchParams(filters).toString();
        return new apiClient({baseURL: process.env.VUE_APP_API_URL, responseType: 'blob'}).get('/submission/export?' + filtersEncoded);
    },
    getSubmissions(filters) {
        let filtersEncoded = new URLSearchParams(filters).toString();
        return new apiClient().get('/submission?' + filtersEncoded);
    },
    getSubmission(id) {
        return new apiClient().get('/submission/'+id);
    },
    postSubmission(record) {
        return new apiClient().post('/submission', record);
    },
    updateSubmission(record) {
        return new apiClient().patch('/submission/'+record.id, record);
    },
    deleteSubmission(id) {
        return new apiClient().delete('/submission/'+id);
    },
    authCheck(authToken) {

        if (authToken === null) {
            console.log('not logged in');
        }

    }
}
