import DocumentCategoryService from '@/services/DocumentCategoryService.js'

export const namespaced = true

export const state = {
        documentCategories: [],
        documentCategoriesTotal: 0,
        document: {},
        isLoading: true,
        meta: {},
    }
export const mutations = {
    ADD(state, document) {
        state.documentCategories.push(document)
    },
    UPDATE(state, document) {
        state.document = document
    },
    DELETE(state, index) {
        state.documentCategories.splice(index)
    },
    SET_LIST(state, documentCategories) {
        state.documentCategories = documentCategories
    },
    SET_META(state, meta) {
        state.meta = meta
    },
    SET_LIST_TOTAL(state, total) {
        state.documentCategoriesTotal = total
    },
    SET_ITEM(state, document) {
        state.document = document
    },
    SET_IS_LOADING(state, isLoading) {
        state.isLoading = isLoading
    }
  }

export const actions = {
    createDocumentCategory({ commit, dispatch}, document) {

        return DocumentCategoryService.postDocumentCategory(document).then(response => {
            commit('ADD', document)
            const notification = {
                type: 'success',
                message: 'New category has been created!'
            }
            dispatch('notification/add', notification, {root: true})

            return response.data.data
        }).catch(error => {
            const notification = {
                type: 'error',
                message: error.response.data.message
            }
            dispatch('notification/add', notification, {root: true})

            const errors = error.response.data.errors
            Object.keys(errors).forEach(key => {
                dispatch('error/add', {key, message: errors[key]}, {root: true})
            });

            throw error
        })
    },
    updateDocumentCategory({ commit, dispatch}, document) {

        return DocumentCategoryService.updateDocumentCategory(document).then(response => {
            commit('UPDATE', response.data.data)

            const notification = {
                type: 'success',
                message: 'Category has been updated!'
            }
            dispatch('notification/add', notification, {root: true})


        }).catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem updating the category: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})

            const errors = error.response.data.errors
            Object.keys(errors).forEach(key => {
                dispatch('error/add', {key, message: errors[key]}, {root: true})
            });

            throw error
        })
    },
    deleteDocumentCategory({ commit, dispatch}, id) {

      const document = getters.getDocumentCategoryById(id)
      const documentIndex = state.documentCategories.indexOf(document);

        return DocumentCategoryService.deleteDocumentCategory(id).then(() => {
            commit('DELETE', documentIndex)

            const notification = {
                type: 'success',
                message: 'Category has been deleted!'
            }
            dispatch('notification/add', notification, {root: true})
        }).catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem deleting the category: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})
            throw error
        })
    },
    fetchDocumentCategories({commit, dispatch}, filters) {
      DocumentCategoryService.getDocumentCategories(filters)
      .then(response => {
        commit('SET_LIST', response.data.data)
        commit('SET_META', response.data.meta)
        if(response.data.meta !== undefined) {
            commit('SET_LIST_TOTAL', response.data.meta.total)
        }
        commit('SET_IS_LOADING', false)
      })
      .catch(error => {
        const notification = {
            type: 'error',
            message: 'There was a problem getting the category: ' + error.message
        }
        dispatch('notification/add', notification, {root: true})
      })
    },
    fetchDocumentCategory({commit, dispatch}, id) {

        DocumentCategoryService.getDocumentCategory(id)
        .then(response => {
          commit('SET_ITEM', response.data.data)
          commit('SET_IS_LOADING', false)
        })
        .catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem getting a document: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})
        })

    }
  }

export const getters = {
    getDocumentCategoryById: state => id => {
      return state.documentCategories.find(document => document.id == id)
    }
  }
