<template>
  <div class="documentSections">
    <h1>Document Section : {{ documentSection.name }}</h1>
    <div>
    <p v-if="isLoading">Loading...</p>
    <template v-else >

      <div class="grid-x grid-margin-x" >
        <div class="panel cell medium-8">
          <h3>Section Details</h3>
          <button class="button" @click="edit">Edit</button>
          <template v-if="!isEditing">
            <p><strong>{{ documentSection.name }}</strong></p>
            <p class="margin">Created: <strong class="info badge badge--lozenge">{{ documentSection.created_at }}</strong></p>
          </template>
          <template v-else>
            <form @submit.prevent="update">

              <BaseInput
                v-model="documentSection.name"
                label="Name"
                type="text"
                name="name"
               />

              <BaseCheckbox
                  v-model="documentSection.preselect"
                  label="Always include documents listed below in this section?"
                  name="preselect"
                  id="preselect"
              />

              <br/>

              <BaseButton type="submit" text="Update" />
          </form>
          </template>

        </div>
        <div class="panel cell medium-4">
          <p>Click below to delete this section.</p>
          <template v-if="!documentSection.protected">
            <BaseButton text="Delete" @click="remove" class="button button--danger" />
          </template>
          <template v-else>
            <p>This documentSection can not be removed</p>
          </template>
        </div>

      </div>
      <div class="grid-x grid-margin-x" >
        <div class="panel cell medium-12">
          <h3 class="no-margin">Sub Sections</h3>
          <form @submit.prevent="createSubSection" class="form-min">
            <BaseInput
              v-model="newSubSection.name"
              label="New Sub Section Title"
             />
            <BaseButton text="Add" />
          </form>


          <template v-if="subSections.length">
            <ListLoop v-for="(item) in subSections" :key="item.id" :item="item" route="documentSubSection-detail" itemName="name" >
              <template v-slot:details>
                <p><strong>{{ item.name }}</strong></p>
                <p class="margin">Created : <strong class="info badge badge--lozenge">{{ item.created_at }}</strong></p>
              </template>
            </ListLoop>
          </template>
          <p v-else class="warning badge badge--lozenge">No sub-sections in this section yet!</p>
        </div>
      </div>
      <div class="grid-x grid-margin-x" >
        <div class="panel cell medium-12">
          <h3>Documents</h3>
          <div class="library-header">
            <BaseButton @click="uploading" :text="isUploadingButton"/>
            <MultiFileUpload v-if="isUploading"
                :contract_id="documentSection.contract_id"
                :document_id="documentSection.document_id"
                :document_section_id="documentSection.id"
              />
          </div>

          <div v-if="files.length" class="grid-x grid-margin-x small-up-2 medium-up-4 large-up-6 xxlarge-up-8">
            <MediaListLoop
                v-for="(media) in files"
                :mediaObject="media"
                :key="media.id"
                @close-reveal="closedReveal"
            />
          </div>
          <p v-else class="warning badge badge--lozenge">No files in this section yet!</p>
        </div>
      </div>
    </template>
    </div>
  </div>
</template>

<script>
    import ListLoop from '@/components/ListLoop.vue'
    import MediaListLoop from '@/components/MediaListLoop.vue'
    import MultiFileUpload from '@/components/MultiFileUpload.vue'
    import { mapState } from 'vuex'

    export default {
        props: ["id"],
        data() {
          return {
            isEditing: false,
            newSubSection: this.createFreshSubSectionObject(),

            isUploading: false,
            isUploadingButton: 'Upload files',

          }
        },
        components : {
          ListLoop,
          MediaListLoop,
          MultiFileUpload
        },
        created() {
          this.$store.dispatch('error/clear')
          this.$store.dispatch('documentSection/fetchDocumentSection', this.id)

        },
        computed: {
          isLoading() {
            return this.documentSection.isLoading
          },
          isDisabled() {
            return false
          },
          ...mapState({
            documentSection: state => state.documentSection.documentSection
          }),
          files() {
            return (this.documentSection.files) ? this.documentSection.files : []
          },
          subSections() {
            return (this.documentSection.sub_sections) ? this.documentSection.sub_sections : []
          },
        },
        methods: {
          remove() {
            this.$store.dispatch('documentSection/deleteDocumentSection', this.id)
          },
          update() {
            this.$store.dispatch('error/clear')
            this.$store.dispatch('documentSection/updateDocumentSection', this.documentSection)
            this.isEditing = false
          },
          edit() {
            this.isEditing = (this.isEditing) ? false : true

          },
          createSubSection() {
            console.log('create new sub section');

            this.newSubSection.document_id = this.documentSection.document_id;
            this.newSubSection.contract_id = this.documentSection.contract_id;
            this.newSubSection.document_section_id = this.documentSection.id;

            this.$store.dispatch('error/clear')
            this.$store.dispatch('documentSubSection/createDocumentSubSection', this.newSubSection).then(() => {
              this.$store.dispatch('documentSection/fetchDocumentSection', this.id)
              this.newSubSection = this.createFreshSubSectionObject()
            }).catch(err => {
                this.error = err
            })
          },
          createFreshSubSectionObject() {

            return {
              name: '',
            }
          },
          uploading() {
            this.isUploading = (this.isUploading) ? false : true
            this.isUploadingButton = (this.isUploading) ? 'Cancel' : 'Upload files'
          },
          uploadFiles() {

          },
          removeFile(fileID) {
            console.log
            this.$store.dispatch('media/deleteMedia', {
              fileID
            }).then(() => {
              this.$store.dispatch('error/clear')
              this.$store.dispatch('documentSection/fetchDocumentSection', this.id)
            }).catch(err => {
              this.error = err
            })
          },
        }

    }
</script>
