import apiClient from './apiClient.js'

export default {
    exportContent(filters) {
        let filtersEncoded = new URLSearchParams(filters).toString();
        return new apiClient({baseURL: process.env.VUE_APP_API_URL, responseType: 'blob'}).get('/content/export?' + filtersEncoded);
    },
    exportContentViews(filters) {
        let filtersEncoded = new URLSearchParams(filters).toString();
        return new apiClient({baseURL: process.env.VUE_APP_API_URL, responseType: 'blob'}).get('/content/views/export?' + filtersEncoded);
    },
    newContent() {
        return new apiClient().get('/content/create');
    },
    getContentList(filters) {
        let filtersEncoded = new URLSearchParams(filters).toString();
        return new apiClient().get('/content?' + filtersEncoded);
    },
    getContent(id) {
        return new apiClient().get('/content/'+id);
    },
    postContent(content) {
        return new apiClient().post('/content', content);
    },
    updateContent(content) {
        return new apiClient().patch('/content/'+content.id, content);
    },
    duplicateContent(filters, id) {
        let filtersEncoded = new URLSearchParams(filters).toString();
        return new apiClient().get('/content/duplicate/'+id+'?' + filtersEncoded);
    },
    deleteContent(id) {
        return new apiClient().delete('/content/'+id);
    },
    postBlock(block) {
        return new apiClient().post('/content-blocks', block);
    },
    updateBlock(blockID, block) {
        return new apiClient().patch('/content-blocks/'+blockID, block);
    },
    deleteBlock(data) {
        return new apiClient().delete('/content-blocks/'+data.id);
    },
    orderBlock(data) {
        return new apiClient().patch('/content-blocks/order/'+ data.content, data.order);
    },
    getViews(filters) {
        let filtersEncoded = new URLSearchParams(filters).toString();
        return new apiClient().get('/content/views?' + filtersEncoded);
    },
    getView(id) {
        return new apiClient().get('/content/views/'+id);
    }
}
