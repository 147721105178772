import ContractTypeService from '@/services/ContractTypeService.js'

export const namespaced = true

export const state = {
        contractTypes: [],
        contractTypesTotal: 0,
        contractType: {},
        isLoading: true,
        meta: {},
    }
export const mutations = {
    ADD(state, contractType) {
        state.contractTypes.push(contractType)
    },
    UPDATE(state, contractType) {
        state.contractType = contractType
    },
    DELETE(state, index) {
        state.contractTypes.splice(index)
    },
    SET_LIST(state, contractTypes) {
        state.contractTypes = contractTypes
    },
    SET_META(state, meta) {
        state.meta = meta
    },
    SET_LIST_TOTAL(state, total) {
        state.contractTypesTotal = total
    },
    SET_ITEM(state, contractType) {
        state.contractType = contractType
    },
    SET_IS_LOADING(state, isLoading) {
        state.isLoading = isLoading
    }
  }

export const actions = {
    createContentType({ commit, dispatch}, contractType) {

        return ContractTypeService.postContentType(contractType).then(response => {
            commit('ADD', contractType)
            const notification = {
                type: 'success',
                message: 'New type has been created!'
            }
            dispatch('notification/add', notification, {root: true})

            return response.data.data
        }).catch(error => {
            const notification = {
                type: 'error',
                message: error.response.data.message
            }
            dispatch('notification/add', notification, {root: true})

            const errors = error.response.data.errors
            Object.keys(errors).forEach(key => {
                dispatch('error/add', {key, message: errors[key]}, {root: true})
            });

            throw error
        })
    },
    updateContentType({ commit, dispatch}, contractType) {

        return ContractTypeService.updateContentType(contractType).then(response => {
            commit('UPDATE', response.data.data)

            const notification = {
                type: 'success',
                message: 'Type has been updated!'
            }
            dispatch('notification/add', notification, {root: true})


        }).catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem updating the type: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})

            const errors = error.response.data.errors
            Object.keys(errors).forEach(key => {
                dispatch('error/add', {key, message: errors[key]}, {root: true})
            });

            throw error
        })
    },
    deleteContentType({ commit, dispatch}, id) {

      const contractType = getters.getContentTypeById(id)
      const contractTypeIndex = state.contractTypes.indexOf(contractType);

        return ContractTypeService.deleteContentType(id).then(() => {
            commit('DELETE', contractTypeIndex)

            const notification = {
                type: 'success',
                message: 'Type has been deleted!'
            }
            dispatch('notification/add', notification, {root: true})
        }).catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem deleting the contractType: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})
            throw error
        })
    },
    fetchContentTypes({commit, dispatch}, filters) {
      ContractTypeService.getContentTypes(filters)
      .then(response => {
        commit('SET_LIST', response.data.data)
        commit('SET_META', response.data.meta)
        if(response.data.meta !== undefined) {
            commit('SET_LIST_TOTAL', response.data.meta.total)
        }
        commit('SET_IS_LOADING', false)
      })
      .catch(error => {
        const notification = {
            type: 'error',
            message: 'There was a problem getting the contrect types: ' + error.message
        }
        dispatch('notification/add', notification, {root: true})
      })
    },
    fetchContentType({commit, dispatch}, id) {

        ContractTypeService.getContentType(id)
        .then(response => {
          commit('SET_ITEM', response.data.data)
          commit('SET_IS_LOADING', false)
        })
        .catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem getting a type: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})
        })

    }
  }

export const getters = {
    getContentTypeById: state => id => {
      return state.contractTypes.find(contractType => contractType.id == id)
    }
  }
