<template>
  <div class="multi-file-upload" :class="{'multi-file-upload--small' : small}"
       @dragover.prevent
       @drop.prevent="onDropFiles">
    <input type="file"
           @change="onFileUpload"
           ref="fileUpload"
           multiple
           style="display: none">
    <p v-if="!showFileUploadProgress && !showFileResizing" >Drop {{filesText}} here or <a href="#" @click="$refs.fileUpload.click()">click here</a> to select {{filesText}}</p>
    <p v-if="showFileUploadProgress" class="uploadProgress" >
      Uploading Files:
      <progress :value=fileUploadProgress max="100"></progress>
    </p>
    <div v-if="showFileResizing" class="fileResizing">
      <p>Cropping and resizing files - This may take a while!</p>
      <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
    </div>
  </div>
  <p v-if="fileUploadSuccess" style="margin-top: 5px">
    Files uploaded:
    <span v-for="(file, key, index) in fileUploadSuccess" :key="index">
        {{ file }}<span v-if="key != fileUploadSuccess.length -1">, </span>
      </span>
  </p>
</template>

<script>
  import axios from 'axios'
  import {authComputed} from '@/store/helpers.js'

  export default {
    data() {
      return {
        selectedFiles: null,
        showFileUploadProgress: false,
        showFileResizing: false,
        fileUploadProgress: 0,
        fileUploadSuccess: null,
        filesText: this.small ? 'a file' : 'files'
      }
    },
    props: {
      small: {
        type: Boolean,
        default: false
      },
      inModal: {
        type: Boolean,
        default: false
      },
      closedReveal:{
        type: [Function, Object],
        default: () => {}
      },
      uniqueKey: {
        type: [String, Number, Boolean],
        default: false
      },
      contract_id: {
        type: Number,
        default: 0
      },
      document_id: {
        type: Number,
        default: 0
      },
      document_section_id: {
        type: Number,
        default: 0
      },
      document_sub_section_id: {
        type: Number,
        default: 0
      }
    },
    computed: {
      ...authComputed
    },
    methods: {
      onFileUpload(event) {
        this.selectedFiles = event.target.files
        if (this.uniqueKey) {
          this.$store.dispatch('media/setKey', this.uniqueKey)
        }
        this.uploadFiles()
      },
      onDropFiles(event) {
        this.selectedFiles = event.dataTransfer.files
        if (this.uniqueKey) {
          this.$store.dispatch('media/setKey', this.uniqueKey)
        }
        this.uploadFiles()
      },
      uploadFiles() {
        let formData = new FormData();
        this.showFileUploadProgress = true;

        for (let i = 0; i < this.selectedFiles.length; i++) {
          let file = this.selectedFiles[i];
          formData.append('files[' + i + ']', file)
          formData.append('contract_id', this.contract_id)
          formData.append('document_id', this.document_id)
          formData.append('document_section_id', this.document_section_id)
          formData.append('document_sub_section_id', this.document_sub_section_id)
        }

            axios.post(process.env.VUE_APP_API_URL + 'api/media', formData, {
              headers: {
                'Authorization': `Bearer ${ this.authToken }`
              },
              onUploadProgress: uploadEvent => {
                console.log('Upload Progress' + Math.round(uploadEvent.loaded / uploadEvent.total * 100) + '%');
                this.fileUploadProgress = Math.round(uploadEvent.loaded / uploadEvent.total * 100)
                if (this.fileUploadProgress === 100) {
                  this.showFileUploadProgress = false
                  this.showFileResizing = true
                }
              }
            })
            .then((response) => {
              this.fileUploadSuccess = response.data.files_uploaded
              this.showFileUploadProgress = false
              this.showFileResizing = false
              if (this.inModal) {
                this.$store.dispatch('media/setSelectedMedia', {
                  mediaID: response.data.file_uploaded_id
                })
                this.closedReveal()
              } else if (this.small) {
                this.$store.dispatch('media/setSelectedMedia', {
                  mediaID: response.data.file_uploaded_id
                })
              } else {
                this.$router.go()
              }
            })
            .catch((error) => {
              console.log(error)
            })
      }
    }
  }
</script>

<style scoped lang="scss">
  .uploadProgress {
    display: block;
    margin-top: 15px;
  }
  .fileResizing{
    display: block;
    margin-top: 15px;
    text-align:center;

    .lds-ring {
      margin: 0 auto;
      display: block;
      position: relative;
      width: 40px;
      height: 40px;
    }
    .lds-ring div {
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: 32px;
      height: 32px;
      margin: 4px;
      border: 4px solid #1c1c1c;
      border-radius: 50%;
      animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: #1c1c1c transparent transparent transparent;
    }
    .lds-ring div:nth-child(1) {
      animation-delay: -0.45s;
    }
    .lds-ring div:nth-child(2) {
      animation-delay: -0.3s;
    }
    .lds-ring div:nth-child(3) {
      animation-delay: -0.15s;
    }
    @keyframes lds-ring {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }

  }
</style>
