<template>
  <div class="documentCategorys">
    <h1>Documetn Category : {{ documentCategory.name }}</h1>
    <div>
    <p v-if="isLoading">Loading...</p>
    <template v-else >

      <div class="grid-x grid-margin-x" >
        <div class="panel cell medium-8">
          <h3>Category Details</h3>
          <button class="button" @click="edit">Edit</button>
          <template v-if="!isEditing">
            <p><strong>{{ documentCategory.name }}</strong></p>
            <p class="margin">Created: <strong class="info badge badge--lozenge">{{ documentCategory.created_at }}</strong></p>
          </template>
          <template v-else>
            <form @submit.prevent="update">

              <BaseInput
                v-model="documentCategory.name"
                label="Name"
                type="text"
                name="name"
               />

              <BaseButton type="submit" text="Update" />
          </form>
          </template>

        </div>
        <div class="panel cell medium-4">
          <p>Click below to delete this category.</p>
          <template v-if="!documentCategory.protected">
            <BaseButton text="Delete" @click="remove" class="button button--danger" />
          </template>
          <template v-else>
            <p>This documentCategory can not be removed</p>
          </template>
        </div>

      </div>
      <div class="grid-x grid-margin-x" >
        <div class="panel cell medium-6">
          <h3>Documents</h3>

          <template v-if="documents.length">
            <ListLoop v-for="(item) in documents" :key="item.id" :item="item" route="documents-detail" itemName="email" >
              <template v-slot:details>
                <p><strong>{{ item.name }}</strong></p>
                <p>{{ item.email }}</p>
                <p class="margin">Created : <strong class="info badge badge--lozenge">{{ item.created_at }}</strong></p>
              </template>
            </ListLoop>
          </template>
          <p v-else class="warning badge badge--lozenge">No documents in this category yet!</p>
        </div>
      </div>
    </template>
    </div>
  </div>
</template>

<script>
    import ListLoop from '@/components/ListLoop.vue'
    import { mapState } from 'vuex'

    export default {
        props: ["id"],
        data() {
          return {
            isEditing: false
          }
        },
        components : {
          ListLoop
        },
        created() {
          this.$store.dispatch('error/clear')
          this.$store.dispatch('documentCategory/fetchDocument', this.id)

        },
        computed: {
          isLoading() {
            return this.documentCategory.isLoading
          },
          isDisabled() {
            return false
          },
          ...mapState({
            documentCategory: state => state.documentCategory.documentCategory
          }),
          documents() {
            return (this.documentCategory.documents) ? this.documentCategory.documents : []
          },
        },
        methods: {
          remove() {
            this.$store.dispatch('documentCategory/deleteDocument', this.id)
          },
          update() {
            this.$store.dispatch('error/clear')
            this.$store.dispatch('documentCategory/updateDocument', this.documentCategory)
            this.isEditing = false
          },
          edit() {
            this.isEditing = (this.isEditing) ? false : true

          }
        }

    }
</script>
