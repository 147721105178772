import apiClient from './apiClient.js'


export default {
    newDocumentSubSection() {
        return new apiClient().get('/document-sub-sections/create');
    },
    getDocumentSubSections(filters) {
        let filtersEncoded = new URLSearchParams(filters).toString();
        return new apiClient().get('/document-sub-sections?' + filtersEncoded);
    },
    getDocumentSubSection(id) {
        return new apiClient().get('/document-sub-sections/'+id);
    },
    postDocumentSubSection(doc) {
        return new apiClient().post('/document-sub-sections', doc);
    },
    updateDocumentSubSection(doc) {
        return new apiClient().patch('/document-sub-sections/'+doc.id, doc);
    },
    deleteDocumentSubSection(id) {
        return new apiClient().delete('/document-sub-sections/'+id);
    },
    authCheck(authToken) {

        if (authToken === null) {
            console.log('not logged in');
        }

    }
}
