<template >
    <div class="card">
      <div class="card-divider" ><h3>Form Fields</h3></div>
      <div class="card-section" >
        <FormBuilderField  v-for="(field, fieldKey) in setting.data" :key="field.title"  :field="field" :fieldKey="fieldKey" />
        <BaseButton text="Add field" @click="addField" />
      </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import FormBuilderField from '@/components/FormBuilderField'

    export default {
        data() {
          return {
            isActive: false,
            blankField: {
                    name: 'source',
                    type: 'select',
                    options: null
                  }
          }
        },
        props: {
          model: {
            type: String,
            default: 'content'
          }
        },
        components : {
          FormBuilderField
        },
        computed: {
          ...mapState({
            setting: state => state.setting.setting,
            isLoading: state => state.setting.isLoading,
          })
        },
        methods: {
          addField() {
            this.setting.data.push(this.blankField)
            this.$store.dispatch('error/clear')
            this.$store.dispatch('setting/updateSetting', this.setting)
          }
        }
    }
</script>

<style scoped>

</style>
